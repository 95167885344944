import { useNotify } from 'react-admin';
import axios from 'axios';

export const RefreshActiveEntitiesButton = () => {
  const notify = useNotify();

  const onClickRefreshActiveEntities = async () => {
    try {
      await axios.get('/tables/active_entities/refresh_active_entities');
      notify(
        'Lists of active advertisers, offers and affiliates have been refreshed',
        { type: 'success' }
      );
    } catch (err) {
      const errorMessage = err.response?.data ? err.response.data : 'Something went wrong';
      notify(errorMessage, { type: 'error' });
    }
  };

  return (
    <div className="container">
      <div className="p-3 my-4 shadow rounded border d-flex justify-content-between align-items-center">
        <p className="h6 m-0">
          Refresh Active Affiliates, Advertisers, & Offers
        </p>
        <button className="btn btn-primary report-checker" onClick={onClickRefreshActiveEntities}>
          Update
        </button>
      </div>
    </div>
  );
};
