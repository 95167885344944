import React from 'react'
import {
   Edit,
   SimpleForm,
   TextInput,
} from 'react-admin'

import { BackButton } from '../../../helperComponents/BackButton'

export const CampaignSettingsEdit = props => {
   return (
     <>
        <BackButton path="/campaign_settings"/>
        <span className="h4">Edit campaign Setting</span>
        <Edit {...props} title=" ">
           <SimpleForm>
              <TextInput source="id" label="ID" disabled={true}/>
              <TextInput source="internal_account_id" label="Internal Account ID" disabled={true}/>
              <TextInput source="campaign_id" label="Campaign ID" fullWidth={true}/>
              <TextInput source="campaign_name" label="Campaign Name" fullWidth={true}/>
           </SimpleForm>
        </Edit>
     </>
   )
}
