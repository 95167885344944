export const RAW_DATA_STATUSES = {
  NOT_FOUND: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
};

export const REGENERATE_STATUSES = {
  NO_ACTION: -1,
  IN_PROGRESS: 0,
  UPDATE_ENTIRE_REPORT: 1,
  UPDATE_7_DAYS: 2,
};

export const REGENERATE_STATUS_LABELS = {
  [REGENERATE_STATUSES.NO_ACTION]: "don't regenerate",
  [REGENERATE_STATUSES.UPDATE_ENTIRE_REPORT]: "regenerate entire report",
  [REGENERATE_STATUSES.UPDATE_7_DAYS]: "update last 7 days"
};

export const PLATFORMS = {
  GOOGLE: 'Google',
  OUTBRAIN: 'Outbrain',
  TABOOLA: 'Taboola',
  BING: 'Bing',
  META: 'Meta',
};

export const REPORT_TYPES = {
  MEDIA_REPORT: 'Google Media Master',
  NETWORK_REPORT: 'Network',
  MEDIA_AFFILIATE_REPORT: 'Google Media Affiliate',
  OUTBRAIN_AD_MASTER: 'Outbrain Ad Master',
  OUTBRAIN_SECTION_MASTER: 'Outbrain Section Master',
  OUTBRAIN_AD_AFFILIATE: 'Outbrain Ad Affiliate',
  OUTBRAIN_SECTION_AFFILIATE: 'Outbrain Section Affiliate',
  BING_NO_IS_MASTER: 'Bing No IS Master',
  TABOOLA_AD_MASTER: 'Taboola Ad Master',
  TABOOLA_SITES_MASTER: 'Taboola Sites Master',
  META_MEDIA_MASTER: 'Meta Media Master',
  META_MEDIA_AFFILIATE: 'Meta Media Affiliate',
};

export const GOOGLE_REPORT_TYPES = {
  MEDIA_REPORT: 'media-report',
  SEARCH_TERMS: 'search-terms-report'
};
