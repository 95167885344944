import {
  FormDataConsumer,
  BooleanInput,
} from 'react-admin';
import { verifyValue } from '../../utils/transformUtils';

export const JsonBooleanInput = ({ source, label, jsonSource, defaultValue = false }) => (
  <FormDataConsumer>
    {({ formData, ...rest }) => (
      <BooleanInput
        label={label}
        source={jsonSource}
        {...rest}
        format={value => verifyValue(value && value[source], defaultValue)}
        parse={value => ({ ...formData[jsonSource], [source]: value })}
      />
    )}
  </FormDataConsumer>
);
