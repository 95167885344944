import React, { useEffect, useState } from 'react';
import {
   Datagrid,
   List,
   TextField,
   TextInput,
   SelectInput,
   SelectArrayInput,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { searchInputWidth } from '../../../../helpers';
import { httpClient } from '../../../../config';
import { platforms } from '../../../../helpers/constants';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';

export const PlatformAccountSettingsList = props => {
   const [users, setUsers] = useState([]);

   const rowClassName = useRowStyle();

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient('/access/data');
            const fetchedReports = JSON.parse(response.body).userData
              .map(({id, username}) => ({id, name: username, value: id}));
            setUsers(fetchedReports);
         };

         doRequest();
      } catch (err) {
         console.log(err);
      }
   }, []);

   return (
     <>
        <TitleAboveTable title="Platform account settings"/>
        <List {...props}
              title=" "
              actions={<ToolBarRowStyleToggle/>}
              pagination={<TablePagination/>}
              filters={[
                 <TextInput source="q"
                            label="ID, Account ID, Account name, User access ID"
                            alwaysOn
                            sx={{
                               width: searchInputWidth,
                            }}
                 />,
                 <SelectInput source="user_access"
                              label="User name"
                              choices={users}
                 />,
                 <SelectArrayInput source="platforms_filter"
                              label="Platforms"
                              choices={platforms}
                 />,
              ]}
        >
           <Datagrid rowClick="edit" bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="Internal Account ID"/>
              <TextField source="platform" label="Platform"/>
              <TextField source="account_id" label="Account ID"/>
              <TextField source="account_name" label="Account Name"/>
           </Datagrid>
        </List>
     </>
   );
};

