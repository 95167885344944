export const setDefaultColumns = ({ preferenceKey, defaultColumns }) => {
  const storedColumns = JSON.parse(localStorage.getItem(`RaStore.preferences.${preferenceKey}`));

  if (!storedColumns || storedColumns.length === 0) {
    localStorage.setItem(`RaStore.preferences.${preferenceKey}`, JSON.stringify(defaultColumns));
  }
};

export const getSelectedColumnsFromLocalStorage = (key) => {
  const availableColumns = localStorage.getItem(`RaStore.preferences.${key}.datagrid.availableColumns`);
  const selectedColumns = localStorage.getItem(`RaStore.preferences.${key}.datagrid.columns`);

  const parsedAvailableColumns = availableColumns ? JSON.parse(availableColumns) : [];
  const parsedSelectedColumns = selectedColumns ? JSON.parse(selectedColumns) : null;

  if (!parsedSelectedColumns) {
    return parsedAvailableColumns.map((col) => col.source);
  }

  return parsedSelectedColumns.map((index) => parsedAvailableColumns[index]?.source);
};

export const getFilterParamsFromLocalStorage = (localStorageName) => {
  try {
    const savedParams = getLocalStorageItem(`RaStore.${localStorageName}.listParams`);
    if (savedParams && savedParams.filter) {
      return {
        startDate: savedParams.filter.start_date || null,
        endDate: savedParams.filter.end_date || null,
      };
    }
  } catch (error) {
    console.error(`Error fetching list params from localStorage for key: ${localStorageName}`, error);
  }
  return { startDate: null, endDate: null };
};

const getLocalStorageItem = (key) => {
  const storedValue = localStorage.getItem(key);
  try {
    return JSON.parse(storedValue);
  } catch (error) {
    console.error(`Error parsing localStorage item for key: ${key}`, error);
    return storedValue;
  }
};

const getAvailableColumnsKey = (baseKey) => {
  return `RaStore.preferences.${baseKey}.datagrid.availableColumns`;
};

const getExistingDataFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key)) || [];
};

const updateFirstColumn = (preferences, isAggregatedView) => {
  const newColumn = isAggregatedView
    ? { index: "0", source: "count", label: "Count" }
    : { index: "0", source: "id", label: "ID" };

  preferences[0] = newColumn;
};

const saveDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const updateFirstColumnInPreferences = ({ baseKey, isAggregatedView }) => {
  const key = getAvailableColumnsKey(baseKey);
  const existingPreferences = getExistingDataFromLocalStorage(key);

  if (existingPreferences.length > 0) {
    updateFirstColumn(existingPreferences, isAggregatedView); 
  }

  saveDataToLocalStorage(key, existingPreferences);
};
