import { fetchUtils } from 'react-admin'
import { updateSessionExpirationTime } from '../helpers/authUtils';

export const apiUrl = ''

export const httpClient = (url, options = {}) => {
   if (!options.headers) {
      options.headers = new Headers({Accept: 'application/json'})
   }
   return fetchUtils.fetchJson(url, options)
   .then(response => {
      const sessionExpiryTime = response.headers.get('X-Session-Expiry-Time');
      if (sessionExpiryTime) {
         updateSessionExpirationTime(sessionExpiryTime);
      }
      return response;
   })
   .catch(err => {
      if (err.status === 401) {
         localStorage.removeItem('isLogin')
         localStorage.removeItem('user')
         localStorage.removeItem('filter')
         document.location.reload()
         return
      }

      if (err.status === 403) {
         window.location.replace('/not-allowed')
         return
      }

      return err
   })
}




