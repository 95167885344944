import { Create } from 'react-admin';
import { MediaPlatformSettingsForm } from './MediaPlatformSettings.Form';
import { BackButton } from '../../../helperComponents/BackButton';
import { useProcessCopiedRecord } from '../../../../hooks/useProcessCopiedRecord';
import { transformMediaPlatformSettings } from '../../../../utils/transformUtils';
import { defaultMediaPlatformCreateRecord } from '../../../../config/constants/recordConstants';

export const MediaPlatformSettingsCreate = (props) => {
  return (
    <>
      <BackButton path="/media_platform_api_settings" />
      <span className="h4">Create Media Platform API Settings</span>
      <Create
        {...props}
        title=" "
        record={useProcessCopiedRecord('media_platform_api_settings') || defaultMediaPlatformCreateRecord}
        transform={transformMediaPlatformSettings}
      >
        <MediaPlatformSettingsForm />
      </Create>
    </>
  );
};
