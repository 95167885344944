export const CampaignIdTooltipContent = ({ campaignIds, campaignsHashMap }) => {
  return (
    <>
      {campaignIds && campaignIds.map(campaignId => (
        <div key={campaignId}>
          {`${campaignId} | ${campaignsHashMap.get(campaignId) || 'Not found'}`}
        </div>
      ))}
    </>
  );
};
