const getSessionExpiryFromLocalStorage = () => {
  const sessionExpiryTime = localStorage.getItem('sessionExpiryTime');
  return sessionExpiryTime ? parseInt(sessionExpiryTime, 10) : null;
};

export const isSessionExpired = () => {
  const currentTimestamp = Date.now();
  const sessionExpiryTimestamp = getSessionExpiryFromLocalStorage();

  return !sessionExpiryTimestamp || currentTimestamp > sessionExpiryTimestamp;
};

export const updateSessionExpirationTime = (sessionExpiryTime) => {
  localStorage.setItem('sessionExpiryTime', sessionExpiryTime);
};

export const checkAccess = ({ role, allowedRoles }) => {
  return allowedRoles.includes(role);
};
