import React, { useState } from 'react'
import axios from 'axios'
import { Button, TextField } from '@mui/material'
import { useNotify, useRedirect } from 'react-admin'


export const CreateArticleDomain = ({ setSelectedArticleDomain, setToggle, setDomainToggle }) => {
   const [articleDomain, setArticleDomain] = useState('')
   const [publisherId, setPublisherId] = useState('')

   const redirect = useRedirect()
   const notify = useNotify()

   const onCreateNewArticleDomain = async () => {
      try {
         const result = await axios.post('/tables/articles_setup', {
            article_domain: articleDomain,
            publisher_id: publisherId,
         })

         const recordId = result.data.id

         if (result.status === 200) {
            notify('Article domain successfully created', { type: 'success' })

            setToggle(prev => !prev)
            setDomainToggle(true)
            setSelectedArticleDomain(result.data.article_domain)
         }

         redirect(`/articles_setup/${recordId}`, 'articles_setup')
      } catch (err) {
         if (err.response.status === 400) {
            notify('Something went wrong', { type: 'error' })
         }
      }
   }

   const onChangeArticleDomain = ({ target }) => {
      setArticleDomain(target.value.trim())
   }

   const onChangePublisherId = ({ target }) => {
      setPublisherId(target.value)
   }

   return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
         <TextField
            value={articleDomain}
            onChange={onChangeArticleDomain}
            variant="filled"
            label="Article Domain"
            sx={{ margin: '0 20px 0' }}
         >
         </TextField>
         <TextField
            value={publisherId}
            onChange={onChangePublisherId}
            variant="filled"
            label="Publisher ID"
            sx={{ margin: '0 20px 0' }}
         >
         </TextField>
         <Button
            onClick={onCreateNewArticleDomain}
            variant="contained"
            color="secondary"
            sx={{ margin: '10px', padding: '10px 20px' }}
         >
            Save Article Domain
         </Button>
      </div>
   )
}