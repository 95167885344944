import { Box, Button, Card, MenuItem, Tab, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useNotify } from 'react-admin';
import { format } from 'date-fns';
import { BackButton } from '../../../../helperComponents/BackButton';
import {
   SAMPLE_SYSTEM_LOCALES,
   REVIEW_STATUSES,
   REVIEW_ACTIONS,
   ADVERTISER_STATUSES,
   ADVERTISER_ACTIONS,
} from '../../../../../helpers/constants';
import { TabContext, TabList, TabPanel } from '@mui/lab';

export const OpenRequestUnit = () => {
   const {
      COMMENTS_TO_AFFILIATE,
   } = SAMPLE_SYSTEM_LOCALES;

   const [rowId, setRowId] = useState('');
   const [affiliateId, setAffiliateId] = useState('');
   const [affiliateName, setAffiliateName] = useState('');
   const [submitDate, setSubmitDate] = useState('');
   const [decisionDate, setDecisionDate] = useState('');
   const [trackingLinkDate, setTrackingLinkDate] = useState('');
   const [advertiserCommentsDate, setAdvertiserCommentsDate] = useState('');
   const [modifiedDate, setModifiedDate] = useState('');
   const [offer, setOffer] = useState('');
   const [samplesRequested, setSamplesRequested] = useState('');
   const [isApproved, setIsApproved] = useState('');
   const [trackingId, setTrackingId] = useState('');
   const [samplesReceived, setSamplesReceived] = useState('');
   const [comments, setComments] = useState('');
   const [advertiserComments, setAdvertiserComments] = useState('');
   const [trackingLink, setTrackingLink] = useState('');

   const [productUrl1, setProductUrl1] = useState('');
   const [productVariant1, setProductVariant1] = useState('');
   const [productReview1, setProductReview1] = useState('');
   const [productUrl2, setProductUrl2] = useState('');
   const [productVariant2, setProductVariant2] = useState('');
   const [productReview2, setProductReview2] = useState('');
   const [productUrl3, setProductUrl3] = useState('');
   const [productVariant3, setProductVariant3] = useState('');
   const [productReview3, setProductReview3] = useState('');
   const [productUrl4, setProductUrl4] = useState('');
   const [productVariant4, setProductVariant4] = useState('');
   const [productReview4, setProductReview4] = useState('');
   const [productUrl5, setProductUrl5] = useState('');
   const [productVariant5, setProductVariant5] = useState('');
   const [productReview5, setProductReview5] = useState('');

   const [name, setName] = useState('');
   const [company, setCompany] = useState('');
   const [country, setCountry] = useState('');
   const [territory, setTerritory] = useState('');
   const [city, setCity] = useState('');
   const [address1, setAddress1] = useState('');
   const [address2, setAddress2] = useState('');
   const [phone, setPhone] = useState('');
   const [email, setEmail] = useState('');
   const [postcode, setPostcode] = useState('');

   const [affiliateStatus, setAffiliateStatus] = useState('');
   const [affiliateAction, setAffiliateAction] = useState('');
   const [advertiserStatus, setAdvertiserStatus] = useState('');
   const [advertiserAction, setAdvertiserAction] = useState('');

   const [toggle, setToggle] = useState(false);
   const notify = useNotify();
   const [tabNumber, setTabNumber] = useState('1');

   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const requestNumber = searchParams.get('request_number');

   useEffect(() => {
      const doRequest = async () => {
         const url = new URL(window.location.href);
         const rowId = url.pathname.split('/').pop();

         const fetchedSampleRequest = await axios.get(`/tables/sample_system/affiliate_sample_requests/get_one/${rowId}`)
            .then(response => response.data);

         setRowId(rowId);
         setAffiliateId(fetchedSampleRequest.affiliate_id);
         setAffiliateName(fetchedSampleRequest.affiliate);
         setSubmitDate(fetchedSampleRequest.submit_date);
         setDecisionDate(fetchedSampleRequest.decision_date);
         setTrackingLinkDate(fetchedSampleRequest.tracking_link_date);
         setAdvertiserCommentsDate(fetchedSampleRequest.advertiser_comments_date);
         setModifiedDate(fetchedSampleRequest.modified_date);
         setOffer(fetchedSampleRequest.offer);
         setSamplesRequested(fetchedSampleRequest.samples_requested);
         setIsApproved(fetchedSampleRequest.is_approved);
         setTrackingId(fetchedSampleRequest.tracking_id);
         setTrackingLink(fetchedSampleRequest.tracking_link);
         setSamplesReceived(fetchedSampleRequest.samples_received);
         setComments(fetchedSampleRequest.comments);
         setAdvertiserComments(fetchedSampleRequest.advertiser_comments);

         setProductUrl1(fetchedSampleRequest.product_1_url);
         setProductVariant1(fetchedSampleRequest.product_1_variant);
         setProductReview1(fetchedSampleRequest.product_1_review);
         setProductUrl2(fetchedSampleRequest.product_2_url);
         setProductVariant2(fetchedSampleRequest.product_2_variant);
         setProductReview2(fetchedSampleRequest.product_2_review);
         setProductUrl3(fetchedSampleRequest.product_3_url);
         setProductVariant3(fetchedSampleRequest.product_3_variant);
         setProductReview3(fetchedSampleRequest.product_3_review);
         setProductUrl4(fetchedSampleRequest.product_4_url);
         setProductVariant4(fetchedSampleRequest.product_4_variant);
         setProductReview4(fetchedSampleRequest.product_4_review);
         setProductUrl5(fetchedSampleRequest.product_5_url);
         setProductVariant5(fetchedSampleRequest.product_5_variant);
         setProductReview5(fetchedSampleRequest.product_5_review);

         setName(fetchedSampleRequest.name);
         setCompany(fetchedSampleRequest.company_name);
         setCountry(fetchedSampleRequest.country);
         setTerritory(fetchedSampleRequest.territory);
         setCity(fetchedSampleRequest.city);
         setAddress1(fetchedSampleRequest.address_1);
         setAddress2(fetchedSampleRequest.address_2);
         setPhone(fetchedSampleRequest.phone);
         setEmail(fetchedSampleRequest.email);
         setPostcode(fetchedSampleRequest.postal_code);

         setAffiliateStatus(fetchedSampleRequest.request_status);
         setAffiliateAction(fetchedSampleRequest.request_action);
         setAdvertiserStatus(fetchedSampleRequest.status_for_advertiser);
         setAdvertiserAction(fetchedSampleRequest.action_for_advertiser);
      };

      doRequest();
   }, [toggle]);

   const onChange = setFunc => ({target}) => {
      setFunc(target.value);
   };

   const onSave = async () => {
      try {
         const isReviewSubmitted = !!(productReview1 || productReview2 || productReview3 || productReview4 || productReview5);

         await axios.put('/tables/sample_system/affiliate_sample_requests', {
            id: rowId,
            status: (isApproved === true || isApproved === false) ? 'closed' : 'open',
            offer: offer,
            is_approved: isApproved,
            tracking_id: trackingId,
            tracking_link: trackingLink,
            samples_received: samplesReceived,
            review_submitted: isReviewSubmitted,
            comments,
            advertiser_comments: advertiserComments,

            product_1_url: productUrl1,
            product_2_url: productUrl2,
            product_3_url: productUrl3,
            product_4_url: productUrl4,
            product_5_url: productUrl5,

            product_1_variant: productVariant1,
            product_2_variant: productVariant2,
            product_3_variant: productVariant3,
            product_4_variant: productVariant4,
            product_5_variant: productVariant5,

            product_1_review: productReview1,
            product_2_review: productReview2,
            product_3_review: productReview3,
            product_4_review: productReview4,
            product_5_review: productReview5,

            name,
            company_name: company,
            country,
            territory,
            city,
            address_1: address1,
            address_2: address2,
            postal_code: postcode,
            phone,
            email,
            request_status: affiliateStatus,
            request_action: affiliateAction,
            status_for_advertiser: advertiserStatus,
            action_for_advertiser: advertiserAction,
         });

         setToggle(prev => !prev);
         notify('Sample Request successfully updated', {type: 'success'});
      } catch (err) {
         if (err.response.status === 400) {
            notify('Something went wrong', {type: 'error'});
         }
      }
   };

   const changeTab = (event, value) => {
      setTabNumber(value);
   };

   return (
      <>
         <BackButton path={`/sample_system/affiliate_sample_requests/${affiliateId}`}/>
         <h5>{affiliateId} | {affiliateName} | sample request # {requestNumber} - Offer: {offer}</h5>
         <div className="row">
            <div className="col-9">
               <Card>
                  <TabContext value={tabNumber}>
                     <TabList onChange={changeTab}>
                        <Tab label="MAIN" value="1"/>
                        <Tab label="PRODUCTS & REVIEWS" value="2"/>
                        <Tab label="ADDRESS/CONTACT INFO" value="3"/>
                     </TabList>

                     <TabPanel value="1">
                        <div className="row">
                           <div className="col-2">
                              <TextField
                                 value={requestNumber}
                                 label="Request Number"
                                 disabled
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={offer}
                                 label="Offer"
                                 fullWidth
                                 disable
                                 onChange={onChange(setOffer)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={samplesRequested}
                                 label="Samples Requested"
                                 type="number"
                                 disabled
                                 fullWidth
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={isApproved}
                                 label="Request Status"
                                 select
                                 fullWidth
                                 onChange={onChange(setIsApproved)}
                                 SelectProps={{
                                    displayEmpty: true,
                                    renderValue: (selected) => {
                                        if (selected === null) {
                                            return <span>Pending</span>;
                                        }
                                        return selected ? 'Sent to Brand' : 'Rejected';
                                    }
                                }}
                                InputLabelProps={{ shrink: true }}
                              >
                                 <MenuItem value={true}>
                                    Sent to Brand
                                 </MenuItem>
                                 <MenuItem value={false}>
                                    Rejected
                                 </MenuItem>
                              </TextField>
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={comments}
                                 label={COMMENTS_TO_AFFILIATE}
                                 fullWidth
                                 multiline
                                 rows={3}
                                 onChange={onChange(setComments)}
                              />
                           </div>
                        </div>
                        <div className="row my-4">
                           <div className="col-6 pr-3">
                              <Card className="p-3">
                                 <Typography variant="h6">
                                    Affiliate Dashboard
                                 </Typography>
                                 <div className="row py-3 px-2">
                                    <TextField
                                       label="Affiliate Status"
                                       select
                                       fullWidth
                                       value={affiliateStatus}
                                       onChange={(e) => setAffiliateStatus(e.target.value)}
                                    >
                                       {Object.entries(REVIEW_STATUSES).map(([key, value]) => (
                                          <MenuItem key={key} value={value}>{value}</MenuItem>
                                       ))}
                                    </TextField>
                                    <TextField
                                       label="Affiliate Action"
                                       select
                                       fullWidth
                                       value={affiliateAction}
                                       onChange={(e) => setAffiliateAction(e.target.value)}
                                       style={{ marginTop: '15px' }}
                                    >
                                       {Object.entries(REVIEW_ACTIONS).map(([key, value]) => (
                                          <MenuItem key={key} value={value}>{value}</MenuItem>
                                       ))}
                                    </TextField>
                                 </div>
                                 <Typography variant="body2" style={{ margin: '6px 0' }}>
                                    Note: Affiliate Status and Action are automated by the system but can be overridden.
                                 </Typography>
                              </Card>
                           </div>
                           <div className="col-6 pl-3">
                              <Card className="p-3">
                                 <Typography variant="h6">
                                    Advertiser Dashboard
                                 </Typography>
                                 <div className="row py-3 px-2">
                                    <TextField
                                       value={trackingLink}
                                       label="Shipment Tracking Link"
                                       onChange={onChange(setTrackingLink)}
                                       fullWidth
                                    />
                                    <TextField
                                       value={advertiserComments}
                                       label="Advertiser Comments"
                                       onChange={onChange(setAdvertiserComments)}
                                       fullWidth
                                       multiline
                                       style={{ margin: '15px 0' }}
                                    />
                                    <TextField
                                       label="Advertiser Status"
                                       select
                                       fullWidth
                                       value={advertiserStatus}
                                       onChange={(e) => setAdvertiserStatus(e.target.value)}
                                       style={{ marginBottom: '15px' }}
                                    >
                                       {Object.entries(ADVERTISER_STATUSES).map(([key, value]) => (
                                          <MenuItem key={key} value={value}>{value}</MenuItem>
                                       ))}
                                    </TextField>
                                    <TextField
                                       label="Advertiser Action"
                                       select
                                       fullWidth
                                       value={advertiserAction}
                                       onChange={(e) => setAdvertiserAction(e.target.value)}
                                    >
                                       {Object.entries(ADVERTISER_ACTIONS).map(([key, value]) => (
                                          <MenuItem key={key} value={value}>{value}</MenuItem>
                                       ))}
                                    </TextField>
                                 </div>
                                 <Typography variant="body2" style={{ margin: '6px 0' }}>
                                    Note: Advertiser Status and Action are automated by system but can be overridden.
                                 </Typography>
                              </Card>
                           </div>
                        </div>
                     </TabPanel>
                     <TabPanel value="2">
                        <div className="row">
                           <div className="col-6">
                              <TextField
                                 value={productUrl1}
                                 label="Product URL 1"
                                 fullWidth
                                 onChange={onChange(setProductUrl1)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productVariant1}
                                 label="Product Variant 1"
                                 fullWidth
                                 onChange={onChange(setProductVariant1)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productReview1}
                                 label="Product Review 1"
                                 fullWidth
                                 onChange={onChange(setProductReview1)}
                              />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-6">
                              <TextField
                                 value={productUrl2}
                                 label="Product URL 2"
                                 fullWidth
                                 onChange={onChange(setProductUrl2)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productVariant2}
                                 label="Product Variant 2"
                                 fullWidth
                                 onChange={onChange(setProductVariant2)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productReview2}
                                 label="Product Review 2"
                                 fullWidth
                                 onChange={onChange(setProductReview2)}
                              />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-6">
                              <TextField
                                 value={productUrl3}
                                 label="Product URL 3"
                                 fullWidth
                                 onChange={onChange(setProductUrl3)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productVariant3}
                                 label="Product Variant 3"
                                 fullWidth
                                 onChange={onChange(setProductVariant3)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productReview3}
                                 label="Product Review 3"
                                 fullWidth
                                 onChange={onChange(setProductReview3)}
                              />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-6">
                              <TextField
                                 value={productUrl4}
                                 label="Product URL 4"
                                 fullWidth
                                 onChange={onChange(setProductUrl4)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productVariant4}
                                 label="Product Variant 4"
                                 fullWidth
                                 onChange={onChange(setProductVariant4)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productReview4}
                                 label="Product Review 4"
                                 fullWidth
                                 onChange={onChange(setProductReview4)}
                              />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-6">
                              <TextField
                                 value={productUrl5}
                                 label="Product URL 5"
                                 fullWidth
                                 onChange={onChange(setProductUrl5)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productVariant5}
                                 label="Product Variant 5"
                                 fullWidth
                                 onChange={onChange(setProductVariant5)}
                              />
                           </div>
                           <div className="col-3">
                              <TextField
                                 value={productReview5}
                                 label="Product Review 5"
                                 fullWidth
                                 onChange={onChange(setProductReview5)}
                              />
                           </div>
                        </div>
                     </TabPanel>
                     <TabPanel value="3">
                        <div className="row">
                           <div className="col-2">
                              <TextField
                                 value={name}
                                 label="Name"
                                 onChange={onChange(setName)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={company}
                                 label="Company"
                                 fullWidth
                                 onChange={onChange(setCompany)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={email}
                                 label="Email"
                                 fullWidth
                                 onChange={onChange(setEmail)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={phone}
                                 label="Phone"
                                 onChange={onChange(setPhone)}
                              />
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-2">
                              <TextField
                                 value={country}
                                 label="Country"
                                 onChange={onChange(setCountry)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={territory}
                                 label={country === 'US' ? 'State' : 'Territory'}
                                 onChange={onChange(setTerritory)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={city}
                                 label="City"
                                 fullWidth
                                 onChange={onChange(setCity)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={address1}
                                 label="Address 1"
                                 fullWidth
                                 onChange={onChange(setAddress1)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={address2}
                                 label="Address 2"
                                 fullWidth
                                 onChange={onChange(setAddress2)}
                              />
                           </div>
                           <div className="col-2">
                              <TextField
                                 value={postcode}
                                 label="Postcode"
                                 onChange={onChange(setPostcode)}
                              />
                           </div>
                        </div>
                     </TabPanel>
                  </TabContext>
                  <div style={{backgroundColor: 'rgb(245, 245, 245)'}}>
                     <div className="d-flex px-3 py-2">
                        <Button variant="contained" onClick={onSave}>Save</Button>
                     </div>
                  </div>
               </Card>
            </div>
            <div className="col-3">
               <Card>
                  <Box margin={2} display="flex" flexDirection="column">
                     <Typography variant="h6">
                        History
                     </Typography>
                     <Box display="flex">
                        <Box display="flex" flexDirection="column">
                           <Typography variant="overline">
                              AFFILIATE SUBMIT DATE
                           </Typography>
                           <span style={{fontSize: '12px'}}>{submitDate && format(new Date(submitDate), 'yyyy-MM-dd HH:mm:ss')}</span>
                        </Box>
                        <Box display="flex" flexDirection="column" marginLeft={5}>
                           <Typography variant="overline">
                              REQUEST STATUS DATE
                           </Typography>
                           <span style={{fontSize: '12px'}}>{decisionDate && format(new Date(decisionDate), 'yyyy-MM-dd HH:mm:ss')}</span>
                        </Box>
                     </Box>

                     <Box display="flex" flexDirection="column" marginY={2}>
                        <Typography variant="overline">
                           SHIPMENT TRACKING ADDED
                        </Typography>
                        <span style={{fontSize: '12px'}}>{trackingLinkDate && format(new Date(trackingLinkDate), 'yyyy-MM-dd HH:mm:ss')}</span>
                     </Box>
                     <Box display="flex" flexDirection="column">
                        <Typography variant="overline">
                           ADVERTISER COMMENTS ADDED
                        </Typography>
                        <span style={{fontSize: '12px'}}>{advertiserCommentsDate && format(new Date(advertiserCommentsDate), 'yyyy-MM-dd HH:mm:ss')}</span>
                     </Box>
                     <Box display="flex" flexDirection="column" marginY={2}>
                        <Typography variant="overline">
                           MODIFIED DATE
                        </Typography>
                        <span style={{fontSize: '12px'}}>{modifiedDate && format(new Date(modifiedDate), 'yyyy-MM-dd HH:mm:ss')}</span>
                     </Box>
                  </Box>
               </Card>
            </div>
         </div>
      </>
   );
};
