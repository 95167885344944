import { useEffect, useState } from 'react'
import { Create, SelectInput, SimpleForm } from 'react-admin'
import axios from 'axios'
import { httpClient } from '../../../../config'

export const AffiliateAccessCreate = () => {
   const [affiliates, setAffiliates] = useState([])

   useEffect(() => {
      const doRequest = async () => {
         const fetchedActive = await axios.get(`/tables/active_entities/get_active_entities`).then(response => response.data)
         setAffiliates(fetchedActive.affiliates)
      }

      doRequest()
      }, [])

   return (
      <Create>
         <SimpleForm>
            <SelectInput
               source="affiliate_id"
               choices={affiliates.map(({affiliate_id, affiliate}) => ({
                  id: affiliate_id,
                  name: `${affiliate_id} | ${affiliate}`,
               }))}
            />
         </SimpleForm>
      </Create>
   )
}