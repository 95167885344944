import { UploadReportPage } from './UploadReportPage'
import { ReportActionsPage } from './ReportActionsPage'
import { DownloadConversionReportPage } from './DownloadConversionReportPage'
import { UpdateTunePage } from './UpdateTunePage'
import {DownloadRawDataPage} from './DownloadRawDataPage'
import { RefreshActiveEntitiesButton } from '../components/RefreshActiveEntitiesButton'
import { checkAccess } from '../../../helpers/authUtils'
import { USER_ROLES } from '../../../config/constants/authConstants'

export const ActionsPage = props => {
   const {role} = JSON.parse(localStorage.getItem('user'))

  if (checkAccess({ role, allowedRoles: [USER_ROLES.CONTENT_MEDIA_MANAGER] })) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h5 style={{ margin: '20px' }}>
          Welcome to AffsMedia Analytics Dashboard
        </h5>
        <div style={{ maxWidth: '700px' }}>
          <RefreshActiveEntitiesButton />
        </div>
      </div>
    );
  }

   return (
     <>
        <div className="row">
           <div className="col">
              <UploadReportPage/>
              {checkAccess({
                role,
                allowedRoles: [
                  USER_ROLES.ADMIN,
                  USER_ROLES.MEDIA_BUYER, 
                  USER_ROLES.MEDIA_MANAGER
                ]
              }) && (
                <UpdateTunePage />
              )}
              {checkAccess({
                role,
                allowedRoles: [
                  USER_ROLES.ADMIN,
                  USER_ROLES.MEDIA_BUYER,
                  USER_ROLES.MEDIA_MANAGER
                ]
              }) && (
                <DownloadRawDataPage />
              )}
           </div>
           <div className="col">
              <DownloadConversionReportPage/>
              <ReportActionsPage/>
              {checkAccess({
                role,
                allowedRoles: [
                  USER_ROLES.ADMIN,
                  USER_ROLES.AFFILIATE_MANAGER,
                  USER_ROLES.MEDIA_MANAGER
                ]
              }) && (
                <RefreshActiveEntitiesButton />
              )}
           </div>
        </div>
     </>
   )
}