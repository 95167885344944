import { Typography, FormHelperText } from '@mui/material';

export const RulesForPresentAccountID = () => (
  <>
    <Typography variant="subtitle1" style={{ marginTop: '20px', fontWeight: 'bold' }}>
      These rules apply to rows where Account ID is present
    </Typography>
    <FormHelperText style={{ margin: '0 0 10px' }}>
      If Google/Meta Campaign ID matches, then we place Name (Sponsored Name) value into all records for the selected reports.
    </FormHelperText>
  </>
);

export const RulesForAbsentAccountID = () => (
  <>
    <Typography variant="subtitle1" style={{ marginTop: '35px', fontWeight: 'bold' }}>
      These rules apply to rows where Account ID is not present
    </Typography>
    <FormHelperText style={{ margin: '0 0 10px' }}>
      If Excluded sub3 value does not match campaign ID in existing report row AND where Offer ID value in this rule setting is present,
      then will set Name (Sponsored Name) Value in row’s Sponsored Name field.
    </FormHelperText>
  </>
);
