import { httpClient } from '../config';

export const generateConvReportOptions = (reports) => {
  return reports.map((report) => (
    <option key={report.id} value={report.id}>
      {report.report_name}
    </option>
  ));
};

export const fetchConversionReports = async () => {
  try {
    const response = await httpClient('/analytics/download_report');
    const data = JSON.parse(response.body);
    return {
      googleConvReports: data.googleConvReports,
      bingConvReports: data.bingConvReports,
      metaConvReports: data.metaConvReports,
    };
  } catch (err) {
    console.error(err);
    return { googleConvReports: [], bingConvReports: [], metaConvReports: [] };
  }
};
