import { useState, useEffect } from 'react';
import { fetchPlatformAccounts } from '../utils/loaders/platformAccountsApi';
import { getMappedPlatformAccounts } from '../utils/platformUtils';

export const usePlatformAccounts = () => {
  const [platformAccounts, setPlatformAccounts] = useState([]);

  useEffect(() => {
    const fetchAndSetPlatformAccounts = async () => {
      try {
        const accounts = await fetchPlatformAccounts();
        const mappedAccounts = getMappedPlatformAccounts(accounts);
        setPlatformAccounts(mappedAccounts);
      } catch (err) {
        console.error('Error fetching platform accounts:', err);
      }
    };

    fetchAndSetPlatformAccounts();
  }, []);

  return platformAccounts;
};
