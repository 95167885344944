import {
   useListContext,
   useNotify,
   useRefresh,
   useUnselectAll,
   useUpdateMany,
   Button,
} from 'react-admin'
import { UpdateVisibilityButton } from '../../../../helperComponents/UpdateVisibilityButton'
import { REGENERATE_STATUSES } from '../../../../../config/constants/reportConstants';

export const BulkUpdateStatus = () => {
   const {selectedIds} = useListContext()
   const refresh = useRefresh()
   const notify = useNotify()
   const unselectAll = useUnselectAll('report_settings')
   const [UpdateStatusWaiting, {isLoading}] = useUpdateMany(
      'report_settings',
      {},
      {
         onSuccess: () => {
            refresh()
            notify('Report statuses updated', {type: 'success'})
            unselectAll()
         },
         onError: error => notify('Error: something went wrong', {type: 'warning'}),
      },
   )

   const onClick = status => () => {
      UpdateStatusWaiting('report_settings', {ids: selectedIds, data: {status}})
   }


   return (
      <>
         <Button
            disabled={isLoading}
            onClick={onClick(REGENERATE_STATUSES.UPDATE_7_DAYS)}
         >
            set to update last 7 days
         </Button>
         <Button
            disabled={isLoading}
            onClick={onClick(REGENERATE_STATUSES.UPDATE_ENTIRE_REPORT)}
         >
            set to regenerate
         </Button>
         <Button
            disabled={isLoading}
            onClick={onClick(REGENERATE_STATUSES.NO_ACTION)}
         >
            cancel regenerate
         </Button>
         <UpdateVisibilityButton />
      </>
   )
}