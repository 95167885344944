import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Clear, Done } from '@mui/icons-material';
import {
   Datagrid,
   List,
   TextField,
   SelectArrayInput,
   DateField,
   FunctionField,
   SelectInput,
   TextInput,
   DateInput,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';


export const ArticlesSetupList = props => {
   const [articleDomains, setArticleDomains] = useState([]);
   const [articleURLs, setArticleURLs] = useState([]);
   const [redirectDomains, setRedirectDomains] = useState([]);

   const rowClassName = useRowStyle();

   useEffect(() => {
      const doRequest = async () => {
         const fetchedArticleDomains = await axios.get('/tables/articles_setup/article_domains').then(res => res.data);
         const fetchedArticleURLs = await axios.get('/tables/articles_setup/article_urls').then(res => res.data);
         const fetchedRedirectDomains = await axios.get('/tables/redirect_domains/all_redirect_domains').then(res => res.data);

         setArticleDomains(fetchedArticleDomains);
         setArticleURLs(fetchedArticleURLs);
         setRedirectDomains(fetchedRedirectDomains);
      };

      doRequest();
   }, []);

   return (
      <>
         <TitleAboveTable title="Articles"/>
         <List {...props}
               title=" "
               actions={<ToolBarRowStyleToggle/>}
               pagination={<TablePagination/>}
               filters={[
                  <TextInput source="q"
                             label="ID, Redirect Link, Redirect Link Subfolder, Destination Link, Article Domain, Article URL, Offer ID, Affiliate ID"
                             alwaysOn
                             sx={{
                                width: '900px',
                             }}
                  />,

                  <SelectArrayInput source="article_domains"
                                    sx={{width: '210px'}}
                                    label="Article Domains"
                                    choices={articleDomains.map(({article_domain}) => ({
                                       id: article_domain,
                                       name: article_domain
                                    }))}
                  />,

                  <SelectArrayInput source="article_urls"
                                    sx={{width: '210px'}}
                                    label="Article URLs"
                                    choices={articleURLs.map(({article_url}) => ({
                                       id: article_url,
                                       name: article_url
                                    }))}
                  />,
                  <SelectArrayInput source="redirect_domains"
                                    sx={{width: '210px'}}
                                    label="Redirect Domains"
                                    choices={redirectDomains.map(({id, redirect_link_domain}) => ({
                                       id: id,
                                       name: redirect_link_domain
                                    }))}
                  />,
                  <DateInput source="publish_date"
                             sx={{width: '210px'}}
                             label="Publish Date"
                  />,
                  <SelectInput source="is_published"
                                    sx={{width: '210px'}}
                                    label="Published"
                                    choices={[{id: true, name: 'Yes'}, {id: false, name: 'No'}]}
                  />,
               ]}
         >
            <Datagrid className={rowClassName} rowClick="edit">
               <TextField source="id" label="ID" disabled={true}/>
               <TextField source="article_id" label="Article ID"/>
               <TextField source="article_url" label="Article URL"/>
               <TextField source="redirect_link_domain" label="Redirect Domain"/>
               <TextField source="number_of_links" label="# of Links"/>
               <FunctionField render={({is_published}) => (
                  is_published ?
                     <Done fontSize="small"/> :
                     <Clear fontSize="small"/>
               )}
                              source="is_published"
                              label="Published"
               />
               <DateField source="publish_date" label="Publish Date"/>
            </Datagrid>
         </List>
      </>
   );
};