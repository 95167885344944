import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
   Datagrid,
   List,
   TextField,
   SelectArrayInput,
   FunctionField,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';
import { Clear, Done } from '@mui/icons-material';


export const RedirectDomainsList = props => {
   const [domains, setDomains] = useState([]);
   const rowClassName = useRowStyle();

   useEffect(() => {
      const doRequest = async () => {
         const fetchedDomains = await axios.get('/tables/redirect_domains');

         setDomains(fetchedDomains.data);
      };

      doRequest();
   }, []);

   return (
      <>
         <TitleAboveTable title="Redirect Domains"/>
         <List {...props}
               title=" "
               actions={<ToolBarRowStyleToggle/>}
               pagination={<TablePagination/>}
               filters={[
                  <SelectArrayInput source="redirect_link_domain_ids"
                                    alwaysOn={true}
                                    sx={{width: '215px'}}
                                    label="Redirect Link Domains"
                                    choices={domains.map(({id, redirect_link_domain}) => ({
                                       id: id,
                                       name: redirect_link_domain
                                    }))}
                  />,
               ]}
         >
            <Datagrid bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
               <TextField source="id" label="ID"/>
               <TextField source="redirect_link_domain" label="Redirect Link Domain"/>
               <FunctionField render={({domain_name_active}) => domain_name_active ? <Done fontSize="small"/> :
                  <Clear fontSize="small"/>}
                              source="domain_name_active"
                              label="Domain Name Active"
               />
            </Datagrid>
         </List>
      </>
   );
};
