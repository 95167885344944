import { Tooltip } from '@mui/material';
import { generateTooltipContent } from '../../../../utils/actionLogs/tooltipUtils';

export const ActionWithTooltip = ({ record }) => {
  return (
    <Tooltip
      title={generateTooltipContent(record)}
      placement="right"
      arrow
      classes={{ popper: 'action-tooltip' }}
    >
      <div style={{
        width: '280px',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
        {record.action}
      </div>
    </Tooltip>
  );
};
