import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Datagrid,
  List,
  ReferenceInput,
  AutocompleteInput,
  SelectArrayInput,
  FunctionField,
  TextInput,
  DateField,
  DateInput,
  useNotify,
  useRefresh,
  BooleanField,
  SelectInput,
  TextField,
} from 'react-admin';
import { Button, Drawer, FormControlLabel, Switch } from '@mui/material';
import { AggregatedRedirectLinksList } from './AggregatedRedirectLinks.List';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ActionsWithoutCreate } from '../../settings/cron_settings/helpers/ActionsWithoutCreate';
import { RedirectLinkRecordSetup } from '../articles_setup/components/RedirectLinkRecordSetup';
import {
  ensureHttpPrefix,
  redirectLinkPositionChoices,
  redirectLinksCustomExporter,
  addHttpsIfMissing,
} from '../articles_setup/helpers/utils';

export const RedirectLinksList = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [domains, setDomains] = useState([]);
  const [articles, setArticles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [redirectLinkSetup, setRedirectLinkSetup] = useState({});
  const [articleId, setArticleId] = useState(null);
  const [publisherId, setPublisherId] = useState(null);
  const [redirectLinkDomainName, setRedirectLinkDomainName] = useState('');
  const [redirectLinkRecords, setRedirectLinkRecords] = useState([]);
  const [isAutoUpdate, setIsAutoUpdate] = useState(false);
  const [selectedArticleDomain, setSelectedArticleDomain] = useState('');
  const [isNewDestLink, setIsNewDestLink] = useState(false);

  const [isArticleToggleOn, setIsArticleToggleOn] = useState(false);
  const [isAggregate, setIsAggregate] = useState(false);
  const [aggregateTotal, setAggregateTotal] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [aggregatedData, setAggregatedData] = useState([]);

  const rowClassName = useRowStyle();
  const location = useLocation();

  useEffect(() => {
    const listParams = localStorage.getItem('RaStore.redirect_links.listParams');
    const parsedListParams = JSON.parse(listParams);

    if (
      parsedListParams &&
      parsedListParams.filter &&
      parsedListParams.filter.offer_id
    ) {
      setIsArticleToggleOn(true);

      const paramsJsonString = JSON.stringify(parsedListParams);
      const params = `?data=${encodeURIComponent(paramsJsonString)}`;

      axios.get(`/tables/redirect_links/aggregated${params}`)
        .then((response) => {
          setAggregateTotal(response.data?.count);
          setAggregatedData(response.data?.aggregatedArticlesSetup);
        })
        .catch((error) => {
          console.error('Error fetching aggregated count:', error);
        });
    } else {
      setIsArticleToggleOn(false);
      setIsAggregate(false);
    }
  }, [location.search, toggle]);

  useEffect(() => {
    const doRequest = async () => {
      const fetchedDomains = await axios
        .get('/tables/redirect_domains')
        .then((res) => res.data);
      const fetchedArticles = await axios
        .get('/tables/articles_setup/article_urls')
        .then((res) => res.data);

      setDomains(fetchedDomains);
      setArticles(fetchedArticles);
    };

    doRequest();
  }, []);

  useEffect(() => {
    const doRequest = async () => {
      setRedirectLinkRecords([]);

      if (articleId) {
        const fetchedRedirectLinkRecords = await axios
          .get(`/tables/redirect_links/records?article_setup_id=${articleId}`)
          .then((data) => data.data);

        setRedirectLinkRecords(fetchedRedirectLinkRecords);
      }
    };

    doRequest();
  }, [selectedArticleDomain, articleId, redirectLinkDomainName]);

  const closeDrawer = () => {
    setRedirectLinkSetup({});
    setIsOpen(false);
  };

  const onRowClick = (id, resource, record) => {
    setSelectedRow(record);
    setIsOpen(true);
    setArticleId(record.article_setup_id);
    setPublisherId(record.publisher_id);
    setRedirectLinkDomainName(record.redirect_link_domain);
    setSelectedArticleDomain(record.article_domain);
  };

  const onSaveToDB = async () => {
    try {
      if (redirectLinkSetup.destinationLink && isOpen) {
        const finalRecord = {
          article_setup_id: articleId,
          redirect_link: redirectLinkSetup.redirectLink,
          offer_id: redirectLinkSetup.offerId,
          affiliate_id: redirectLinkSetup.affiliateId,
          sub_brand: redirectLinkSetup.subBrand,
          position: redirectLinkSetup.position,
          params: redirectLinkSetup.params,
          destination_link: redirectLinkSetup.destinationLink,
          deep_link: redirectLinkSetup.deepLink,
        };

        if (redirectLinkSetup.id) {
          finalRecord.id = redirectLinkSetup.id;
        }

        if (
          redirectLinkSetup.redirectLink?.trim() ===
          selectedRow?.article_url?.trim()
        ) {
          return notify('Redirect Link cannot be the same as Article URL', {
            type: 'error',
          });
        }

        const result = await axios.post('/tables/redirect_links', {
          records: [finalRecord],
        });

        if (result.status === 200) {
          notify('Record successfully created/updated', { type: 'success' });
          refresh();
        } else {
          return notify(result?.data || 'Something went wrong', {
            type: 'error',
          });
        }

        setIsAutoUpdate(false);
        const fetchedRedirectLinkRecords = await axios
          .get(
            `/tables/redirect_links/records?article_setup_id=${selectedRow?.id}`
          )
          .then((data) => data.data);
        setRedirectLinkRecords(fetchedRedirectLinkRecords);
        setToggle(!toggle);
      }
    } catch (err) {
      if (err.response.status === 400) {
        notify('Something went wrong', { type: 'error' });
      }
    }
  };

  return (
    <>
      <TitleAboveTable title="Redirect Links" />
      <div className="px-2">
        <h6>
          Click Article ID to open Article setup page, Article URL will open
          article in a new tab, click anywhere else on row to edit links from
          this page.
        </h6>
      </div>
      <List
        {...props}
        title=" "
        actions={<ActionsWithoutCreate />}
        exporter={redirectLinksCustomExporter}
        pagination={
          <TablePagination totalOverride={isAggregate && aggregateTotal} />
        }
        filters={[
          <TextInput
            source="q"
            label="Article ID, Redirect Link, Article URL, Redirect Link, Destination Link, Offer ID, Affiliate ID"
            alwaysOn
            sx={{
              width: '900px',
            }}
          />,
          <SelectArrayInput
            source="redirect_domains"
            alwaysOn={true}
            label="Redirect Link Domains"
            sx={{ width: '300px' }}
            choices={domains.map(({ id, redirect_link_domain }) => ({
              id,
              name: redirect_link_domain,
            }))}
          />,
          <DateInput
            source="publish_date"
            sx={{ width: '210px' }}
            label="Publish Date"
          />,
          <ReferenceInput
            label="Offer"
            source="offer_id"
            reference="active_entities/active_offers"
            sort={{ field: 'offer', order: 'ASC' }}
          >
            <AutocompleteInput
              sx={{ width: '450px' }}
              optionText={(choice) => `${choice.id} - ${choice.offer}`}
            />
          </ReferenceInput>,
          <ReferenceInput
            label="Affiliate"
            source="affiliate_id"
            reference="active_entities/active_affiliates"
            sort={{ field: 'affiliate', order: 'ASC' }}
          >
            <AutocompleteInput
              sx={{ width: '450px' }}
              optionText={(choice) => `${choice.id} - ${choice.affiliate}`}
            />
          </ReferenceInput>,
          <SelectInput
            source="is_published"
            sx={{ width: '210px' }}
            label="Published?"
            choices={[
              { id: true, name: 'Yes' },
              { id: false, name: 'No' },
            ]}
          />,
          <AutocompleteInput
            source="position"
            label="Position"
            choices={redirectLinkPositionChoices}
            optionText="name"
            optionValue="id"
            sx={{ width: '210px' }}
          />,
        ]}
      >
        {isArticleToggleOn && (
          <FormControlLabel
            control={
              <Switch
                checked={isAggregate}
                onChange={() => setIsAggregate(!isAggregate)}
              />
            }
            label="Aggregate by Article"
            style={{ padding: '18px' }}
          />
        )}
        <div style={{ width: '100%' }}>
          {isAggregate ? (
            <AggregatedRedirectLinksList
              rowClick={onRowClick}
              setIsOpen={setIsOpen}
              selectedRow={selectedRow}
              isOpen={isOpen}
              data={aggregatedData}
              toggle={toggle}
            />
          ) : (
            <Datagrid
              rowClick={onRowClick}
              bulkActionButtons={<EmptyBulkActionButtons />}
              className={rowClassName}
              rowStyle={(record) =>
                record.id === selectedRow?.id && isOpen
                  ? { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                  : {}
              }
            >
              <TextField source="id" label="ID"/>
              <FunctionField
                source="article_id"
                label="Article ID"
                render={(record) => {
                  if (record.articles_setup_id) {
                    return (
                      <Link
                        to={`/articles_setup/${record.articles_setup_id}`}
                        style={{ textDecoration: 'underline' }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {record.article_id}
                      </Link>
                    );
                  } else {
                    return record.article_id;
                  }
                }}
              />
              <FunctionField
                source="article_url"
                label="Article URL"
                render={(record) => {
                  const correctedURL =
                    record.article_url && ensureHttpPrefix(record.article_url);
                  return (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={correctedURL}
                      style={{ textDecoration: 'underline' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {correctedURL}
                    </Link>
                  );
                }}
              />
              <FunctionField
                source="redirect_link"
                label="Redirect Link"
                render={(record) => (
                  <div
                    style={{
                      width: '270px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {addHttpsIfMissing(record.redirect_link)}
                  </div>
                )}
              />
              <FunctionField
                source="destination_link"
                label="Destination Link"
                render={(record) => (
                  <div
                    style={{
                      width: '350px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {record.destination_link}
                  </div>
                )}
              />
              <FunctionField
                source="position"
                label="Position"
                render={(record) =>
                  record.position && `Position ${record.position}`
                }
              />
              <FunctionField
                source="offer"
                label="Offer"
                render={(record) =>
                  record.offer_id &&
                  `${record.offer_id} | ${record.offer?.split('-')[0].trim()}`
                }
              />
              <FunctionField
                source="affiliate"
                label="Affiliate"
                render={(record) =>
                  record.affiliate_id &&
                  `${record.affiliate_id} | ${record.affiliate
                    ?.split('-')[0]
                    .trim()}`
                }
              />
              <BooleanField source="is_published" label="Published?" />
              <DateField source="publish_date" label="Publish Date" />
            </Datagrid>
          )}
          <Drawer
            variant="persistent"
            anchor="right"
            open={isOpen}
            onClose={closeDrawer}
          >
            <div
              style={{
                width: 800,
                padding: '25px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
              }}
            >
              {isOpen && (
                <>
                  <Button
                    onClick={closeDrawer}
                    style={{ margin: '35px 0 15px' }}
                  >
                    X
                  </Button>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <RedirectLinkRecordSetup
                      redirectLinkRecord={selectedRow}
                      setRedirectLinkSetup={setRedirectLinkSetup}
                      articleId={articleId || ''}
                      publisherId={publisherId}
                      redirectDomainName={redirectLinkDomainName}
                      redirectLinkRecords={redirectLinkRecords}
                      isNewDestLink={isNewDestLink}
                      setIsNewDestLink={setIsNewDestLink}
                      isAutoUpdate={isAutoUpdate}
                      setIsAutoUpdate={setIsAutoUpdate}
                      key={selectedRow ? selectedRow.id : 'empty'}
                    />
                  </div>
                  <Button
                    onClick={onSaveToDB}
                    variant="contained"
                    color="secondary"
                    sx={{ margin: '20px', width: '85px', top: '-120px' }}
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </Drawer>
        </div>
      </List>
    </>
  );
};
