import { useEffect, useState } from 'react';
import { useRemoveFromStore, useStore } from 'react-admin';
import { copiedFieldsToRemove } from '../helpers/constants';
import { removeFieldsFromRecord } from '../utils/dashboardUtils';

export const useProcessCopiedRecord = (tableName) => {
  const [processedRecord, setProcessedRecord] = useState(null);
  const storeKey = `${tableName}.record_copy`;
  const [copiedRecord] = useStore(storeKey, null);
  const remove = useRemoveFromStore();

  useEffect(() => {
    if (copiedRecord) {
      const filteredRecord = removeFieldsFromRecord(
        copiedRecord,
        copiedFieldsToRemove[tableName] || []
      );

      setProcessedRecord(filteredRecord);
    }

    remove(storeKey);
  }, [copiedRecord, remove, tableName, storeKey]);

  return processedRecord;
};
