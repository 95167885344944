import { httpClient } from '../../config';

export const fetchAffiliates = async () => {
  try {
    const { json } = await httpClient('/tables/active_entities/get_active_entities');
    return json.affiliates;
  } catch (error) {
    console.error("Error fetching affiliates:", error);
    throw error;
  }
};
