import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'react-admin';
import { apiUrl, httpClient } from '../../../config';
import { RegenerateReportForm } from '../components/reportComponents/RegenerateReportForm';
import { REGENERATE_STATUSES } from '../../../config/constants/reportConstants';
import { USER_ROLES } from '../../../config/constants/authConstants';

export const ReportActionsPage = props => {
   const [reports, setReports] = useState([])
   const [reportToRegenerateId, setReportToRegenerateId] = useState(null)
   const [reportToRegenerateLast7DaysId, setReportToRegenerateLast7DaysId] = useState(null)
   const [toShowReportName, setToShowReportName] = useState(null)
   const [isSuccess, setIsSuccess] = useState(null)
   const [role, setRole] = useState(2)
   const [theme] = useTheme()

   const selectRef = useRef()
   const selectLast7DaysRef = useRef()

   const isDarkMode = theme?.palette.mode === 'dark'

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient(`${apiUrl}/analytics/report_actions`)
            const fetchedReports = JSON.parse(response.body).reports
            setReports(fetchedReports)
         }
         setRole(JSON.parse(localStorage.getItem('user')).role)

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   const onUpdateNames = async ({target}) => {
      try {
         target.setAttribute('disabled', 'true')
         target.textContent = 'Updating...'
         target.classList.remove('btn-success')
         target.classList.remove('btn-danger')
         const response = await httpClient(`${apiUrl}/analytics/report_actions/update_names`, {
            method: 'POST',
         })
         const data = JSON.parse(response.body)

         if (data.status === 1) {
            target.textContent = 'Done'
            target.classList.add('btn-success')
         } else {
            target.textContent = 'Error, try again'
            target.classList.add('btn-danger')
         }

         target.removeAttribute('disabled')
      } catch (err) {
         console.log(err)
      }
   }

   const onChange = setFunc => ({target}) => {
      setIsSuccess(null)
      setToShowReportName(target.value)
      setFunc(target.value)
   }

   const onMouseOn = async () => {
      const response = await httpClient(`${apiUrl}/analytics/report_actions`)
      const fetchedReports = JSON.parse(response.body).reports
      setReports(fetchedReports)
   }

   return (
     <>
        <div className="container content">
           <div className="p-3 mt-4 shadow rounded border">
              <p className="h5">
                 Report actions
              </p>
              {isSuccess === true ?
                <div className="alert alert-success mt-4">
                   Regenerating: {+toShowReportName === 0 ? 'All' : reports.map(report => {
                   if (report.id === +toShowReportName) {
                      return <span>{report.report_name}</span>
                   }
                })}
                </div>
                :
                null
              }
              <div className="d-flex justify-content-between mb-3 row align-items-center">
                 <div className="d-flex justify-content-between align-items-center my-3">
                    <p className="h6">Update report names</p>
                    <div>
                       <button className="btn btn-primary report-checker" onClick={onUpdateNames}>Update</button>
                    </div>
                 </div>
                 <hr/>
                 <RegenerateReportForm
                  reports={reports}
                  selectRef={selectLast7DaysRef}
                  isDarkMode={isDarkMode}
                  onChange={onChange(setReportToRegenerateLast7DaysId)}
                  onMouseOn={onMouseOn}
                  label="Regenerate report for last 7 days"
                  reportToRegenerateId={reportToRegenerateLast7DaysId}
                  setReportToRegenerateId={setReportToRegenerateLast7DaysId}
                  setIsSuccess={setIsSuccess}
                  status={REGENERATE_STATUSES.UPDATE_7_DAYS}
                 />
                 {role === USER_ROLES.ADMIN && (
                  <RegenerateReportForm
                    reports={reports}
                    selectRef={selectRef}
                    isDarkMode={isDarkMode}
                    onChange={onChange(setReportToRegenerateId)}
                    onMouseOn={onMouseOn}
                    label="Regenerate report"
                    reportToRegenerateId={reportToRegenerateId}
                    setReportToRegenerateId={setReportToRegenerateId}
                    setIsSuccess={setIsSuccess}
                    status={REGENERATE_STATUSES.UPDATE_ENTIRE_REPORT}
                  />
                 )}
              </div>
           </div>
        </div>
     </>
   )
}