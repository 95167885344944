import { useEffect, useState } from 'react';
import {
  List,
  SelectColumnsButton,
  FilterButton
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { MetaApiDataGrid } from './MetaApiDataGrid';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { DocumentationButton } from '../../../helperComponents/DocumentationButton';
import { AggregateBySelect } from '../../../helperComponents/AggregateBySelect';
import { DateRangeFilter } from '../../../helperComponents/DateRangeFilter';
import { ExportAllDataButton } from './components/ExportAllDataButton';
import { ActionsWithoutCreate } from '../../settings/cron_settings/helpers/ActionsWithoutCreate';
import { getMetaApiDataAggregatedFilters } from '../../../../utils/filters/metaApiDataFilters';
import { getFilterParamsFromLocalStorage, updateFirstColumnInPreferences } from '../../../../utils/localStorageUtils';
import { loadInitialDates } from '../../../../utils/dateUtils';
import { getAggregateValue } from '../../../../utils/aggregatedTablesUtils';

export const AggregatedMetaApiDataList = (props) => {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [firstRecordTime, setFirstRecordTime] = useState(null);

  const handleRowClick = (id, basePath, record) => {
    const queryParams = parse(window.location.search);
    const currentFilters = queryParams.filter ? JSON.parse(queryParams.filter) : {};
    const aggregateValue = getAggregateValue('meta_api_data');
    const { startDate, endDate } = getFilterParamsFromLocalStorage('meta_api_data/aggregated-pixel') || {};
  
    const newFilters = { 
      ...currentFilters, 
      [aggregateValue]: record[aggregateValue],
      ...(startDate ? { start_date: startDate } : {}),
      ...(endDate ? { end_date: endDate } : {})
    };

    navigate({
      pathname: '/meta_api_data',
      search: `?filter=${encodeURIComponent(JSON.stringify(newFilters))}&displayedFilters=${encodeURIComponent(JSON.stringify({ ...queryParams.displayedFilters }))}`,
    });
  };

  useEffect(() => {
    const loadDates = async () => {
      const { dateRange, firstRecordTime } = await loadInitialDates({ 
        localStorageKey: 'meta_api_data/aggregated-pixel', 
        endpoint: '/tables/meta_api_data/oldest-record-time' 
      });
      setDateRange(dateRange);
      setFirstRecordTime(firstRecordTime);
    };

    loadDates();
  }, [localStorage.getItem('aggregate')]);

  useEffect(() => {
    updateFirstColumnInPreferences({ 
      baseKey: 'meta_api_data',
      isAggregatedView: true
    });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <TitleAboveTable title="Meta API Data" />
      </div>
      <List
        {...props}
        title=" "
        actions={<ActionsWithoutCreate
          showExportButton={false}
          showFilterButton={false}
          AdditionalButton={
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}>
                <DateRangeFilter dateRange={dateRange} firstRecordTime={firstRecordTime} />
                <DocumentationButton reference="meta-api-documentation" label="Meta Data API Documentation" />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AggregateBySelect tableName='meta_api_data' localStorageName='meta_api_data/aggregated-pixel' />
                <SelectColumnsButton preferenceKey="meta_api_data.datagrid" />
                <ExportAllDataButton isAggregated />
                <FilterButton/>
              </div>
            </div>
          }
        />}
        pagination={<TablePagination />}
        perPage={25}
        filters={getMetaApiDataAggregatedFilters()}
      >
        <MetaApiDataGrid isAggregated={true} rowClick={handleRowClick} />
      </List>
    </>
  );
};
