export const hasDetails = (record) => {
  return record.details && Object.keys(record.details).length > 0;
};

export const generateTooltipContent = (record) => {
  if (hasDetails(record)) {
    return (
      <pre style={{ maxHeight: '600px', overflowY: 'auto' }}>
        {JSON.stringify(record.details, null, 2)}
      </pre>
    );
  }

  return record.action;
};
