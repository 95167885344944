import React, { useEffect, useState } from 'react'
import {
   Create,
   SimpleForm,
   TextInput,
   SelectInput,
} from 'react-admin'

import { BackButton } from '../../../helperComponents/BackButton'
import { httpClient } from '../../../../config'
import { platforms } from '../../../../helpers/constants'

export const CampaignSettingsCreate = props => {
   const [platformAccounts, setPlatformAccounts] = useState([])
   const [selectedPlatform, setSelectedPlatform] = useState(null)

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient(`/access/data`)
            const fetchedPlatformAccounts = JSON.parse(response.body).accountData
              .map(account => ({id: account.id, platform: account.platform, name: `${account.account_name} - ${account.id}`}))

            setPlatformAccounts(fetchedPlatformAccounts)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   const onChange = setFunc => ({target}) => setFunc(target.value)

   return (
     <>
        <BackButton path="/campaign_settings"/>
        <span className="h4">Create campaign Setting</span>
        <Create {...props} title=" ">
           <SimpleForm>
              <SelectInput source="platform"
                           label="Platform"
                           choices={platforms}
                           onChange={onChange(setSelectedPlatform)}
              />
              <SelectInput source="internal_account_id"
                           label={!selectedPlatform ? 'First select platform' : 'Internal Account ID'}
                           choices={platformAccounts.filter(account => account.platform === selectedPlatform)}
                           disabled={!selectedPlatform}
                           style={{
                              width: '200px'
                           }}
              />
              <TextInput source="campaign_id" label="Campaign ID" fullWidth={true}/>
              <TextInput source="campaign_name" label="Campaign Name" fullWidth={true}/>
           </SimpleForm>
        </Create>
     </>
   )
}
