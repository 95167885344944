import React, { useEffect, useState } from 'react'
import {
   Create,
   SimpleForm,
   TextInput,
   NumberInput, SelectArrayInput,
} from 'react-admin'

import { transform } from '../../../../helpers'
import { BackButton } from '../../../helperComponents/BackButton'
import { httpClient } from '../../../../config'


export const TopBrandsSettingsCreate = props => {
   const [reports, setReports] = useState([])

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient(`/tables/reference_fields/report_names`)
            const fetchedReports = JSON.parse(response.body) || []

            setReports(fetchedReports)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   return (
     <>
        <BackButton path="/top_brand_settings"/>
        <span className="h4">Create top brand setting</span>
        <Create {...props} title=" " transform={transform('report_ids')}>
           <SimpleForm>
              <NumberInput source="affiliate_id" label="Affiliate ID"/>
              <NumberInput source="offer_id" label="Offer ID"/>
              <TextInput source="top_brand" label="Top Brand"/>
              <SelectArrayInput source="report_ids"
                                label="Report IDs"
                                choices={reports.map(({id, report_name}) => ({id, name: `${report_name} - ${id}`}))}
              />
           </SimpleForm>
        </Create>
     </>
   )
}