import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
   useNotify,
   useRedirect,
   TabbedForm,
   FormTab,
   Toolbar,
   SaveButton,
} from 'react-admin';
import axios from 'axios';
import { format } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Toolbar as MuiToolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import {
   Button,
   FormControl,
   MenuItem,
   TextField,
   Drawer,
} from '@mui/material';
import { BackButton } from '../../../helperComponents/BackButton';
import { margin } from './helpers/constants';
import { redirectLinksSetupColumns, prepareRedirectLinksRow } from './helpers/utils';
import { retrieveNumberFromString } from './helpers/utils';
import { CreateArticleDomain } from './components/CreateArticleDomain';
import { RedirectLinkRecordSetup } from './components/RedirectLinkRecordSetup';

export const ArticlesSetupEdit = props => {
   const [toggle, setToggle] = useState(false);

   const [articleDomains, setArticleDomains] = useState([]);
   const [selectedArticleDomain, setSelectedArticleDomain] = useState('');
   const [publisherId, setPublisherId] = useState(null);
   const [articleURL, setArticleURL] = useState('');
   const [articleId, setArticleId] = useState(null);
   const [isPublished, setIsPublished] = useState(false);
   const [publishDate, setPublishDate] = useState(null);
   const [redirectDomains, setRedirectDomains] = useState([]);
   const [selectedRedirectDomain, setSelectedRedirectDomain] = useState('');

   const [record, setRecord] = useState('');

   const [redirectLinkRecords, setRedirectLinkRecords] = useState([]);
   const [redirectLinkSetup, setRedirectLinkSetup] = useState({});
   const [redirectLinkDomainName, setRedirectLinkDomainName] = useState('');

   const [isOpen, setIsOpen] = useState(false);
   const [selectedRow, setSelectedRow] = useState(null);
   const [domainToggle, setDomainToggle] = useState(false);
   const [isNewDestLink, setIsNewDestLink] = useState(false);
   const [isAutoUpdate, setIsAutoUpdate] = useState(false);
   const [selectedRows, setSelectedRows] = useState([]);

   const redirect = useRedirect();
   const notify = useNotify();

   useLayoutEffect(() => {
      const doRequest = async () => {
         const recordId = retrieveNumberFromString(window.location.pathname);

         if (+recordId) {
            const fetchedRecord = await axios.get(`/tables/articles_setup/${recordId}`)
               .then(response => response.data);

            setIsPublished(false);
            setPublishDate(null);

            const {
               article_domain,
               article_url,
               article_id,
               redirect_domain_id,
               is_published,
               publish_date,
               publisher_id,
            } = fetchedRecord;

            setRecord(fetchedRecord);

            article_domain && setSelectedArticleDomain(article_domain);
            publisher_id && setPublisherId(publisher_id);
            article_url && setArticleURL(article_url.replace(`${article_domain}/`, ''));
            article_id && setArticleId(article_id);
            is_published && setIsPublished(is_published);
            publish_date && setPublishDate(format(new Date(publish_date), 'yyyy-MM-dd'));
            redirect_domain_id && setSelectedRedirectDomain(redirect_domain_id);
         }

         const fetchedArticleDomains = await axios.get('/tables/articles_setup/article_domains').then(response => response.data);
         const fetchedRedirectDomains = await axios.get('/tables/redirect_domains/all_redirect_domains').then(response => response.data);

         setArticleDomains(fetchedArticleDomains);
         setRedirectDomains(fetchedRedirectDomains.filter(domain => domain.domain_name_active));
      };

      doRequest();
   }, [toggle]);

   const handleRowClick = (param, event) => {
      const redirectLinkSetupRecord = redirectLinkRecords.find(record => record.id === param.row?.id);

      setSelectedRow(redirectLinkSetupRecord || null);
      setIsOpen(true);
      setIsAutoUpdate(false);
   };

   const closeDrawer = () => {
      setRedirectLinkSetup({});
      setIsOpen(false);
   };

   const handleAddNewLinkClick = (param, event) => {
      const redirectLinkSetupRecord = redirectLinkRecords.find(record => record.id === param.row?.id);

      setSelectedRow(redirectLinkSetupRecord || null);
      setIsOpen(true);
      setIsNewDestLink(true);
      setIsAutoUpdate(true);
   };

   useEffect(() => {
      const doRequest = async () => {
         setRedirectLinkRecords([]);

         if (record.id) {
            const fetchedRedirectLinkRecords = await axios.get(
               `/tables/redirect_links/records?article_setup_id=${record.id}`,
            ).then(data => data.data);

            setRedirectLinkRecords(fetchedRedirectLinkRecords);
         }
      };

      selectedArticleDomain !== 'create' && doRequest();

   }, [selectedArticleDomain, record, redirectLinkDomainName]);

   useEffect(() => {
      setRedirectLinkDomainName(redirectDomains.find(domain => domain.id === selectedRedirectDomain)?.redirect_link_domain);
   });

   const handleDelete = async () => {
      try {
         const deletePromises = selectedRows.map(rowId =>
            axios.delete(`/tables/redirect_links/${rowId}`)
         );
         await Promise.all(deletePromises);
  
         notify('Records successfully deleted', { type: 'success' });

         const newRecords = redirectLinkRecords.filter(record => !selectedRows.includes(record.id));

         setRedirectLinkRecords(newRecords);
         setSelectedRows([]);
      } catch (err) {
          notify('Error deleting records', { type: 'error' });
      }
   };  

   const onSaveToDB = async () => {
      if (isPublished && !publishDate) {
         return notify('Please pick a date', { type: 'warning' });
      } else if (!selectedArticleDomain && !articleURL) {
         return notify('Please fill in Article URL and ID on main tab', { type: 'warning' });
      }

      const fetchedArticleURLs = await axios.get('/tables/articles_setup/article_urls')
         .then(response => response.data);

      if (fetchedArticleURLs.find(fetchedArticle => fetchedArticle.article_url === `${selectedArticleDomain}/${articleURL}`
         && fetchedArticle.id !== record?.id)) {
         return notify('Article URL already exists', { type: 'warning' });
      }

      try {
         const res = record
            ? await axios.put(`/tables/articles_setup/${record.id}`, {
               article_domain: selectedArticleDomain,
               publisher_id: publisherId,
               article_url: `${selectedArticleDomain}/${articleURL}`,
               article_id: articleId,
               is_published: isPublished,
               publish_date: publishDate,
               redirect_domain_id: selectedRedirectDomain,
            })
            : await axios.post('/tables/articles_setup', {
               article_domain: selectedArticleDomain,
               publisher_id: publisherId,
               article_url: `${selectedArticleDomain}/${articleURL}`,
               article_id: articleId,
               is_published: isPublished,
               publish_date: publishDate,
               redirect_domain_id: selectedRedirectDomain,
            });

         const recordId = res.data.id;

         !record && redirect(`/articles_setup/${recordId}`, 'articles_setup');
         setToggle(prev => !prev);

         notify('Done', { type: 'success' });

         if (redirectLinkSetup.destinationLink && isOpen) {
            const finalRecord = {
               article_setup_id: (record && record.id) || recordId,
               redirect_link: redirectLinkSetup.redirectLink,
               offer_id: redirectLinkSetup.offerId,
               affiliate_id: redirectLinkSetup.affiliateId,
               sub_brand: redirectLinkSetup.subBrand,
               position: redirectLinkSetup.position,
               params: redirectLinkSetup.params,
               destination_link: redirectLinkSetup.destinationLink,
               deep_link: redirectLinkSetup.deepLink,
            };

            if (redirectLinkSetup.id) {
               finalRecord.id = redirectLinkSetup.id;
            }

            if (redirectLinkSetup.redirectLink?.trim() === `${selectedArticleDomain}/${articleURL}`.trim()) {
               return notify('Redirect Link cannot be the same as Article URL', { type: 'error' });
            }

            const result = await axios.post('/tables/redirect_links', {
               records: [finalRecord]
            });

            if (result.status === 200) {
               notify('Record successfully created/updated', { type: 'success' });
            } else {
               return notify(result?.data || 'Something went wrong', { type: 'error' });
            }

            setSelectedRow(result?.data || null);

            setIsNewDestLink(false);
            const fetchedRedirectLinkRecords = await axios.get(
               `/tables/redirect_links/records?article_setup_id=${record.id}`,
            ).then(data => data.data);
            setRedirectLinkRecords(fetchedRedirectLinkRecords);
         }

      } catch (err) {
         if (err.response.status === 400) {
            notify('Something went wrong', { type: 'error' });
         }
      }
   };

   const CustomToolbar = props => {
      return (
         <Toolbar {...props}>
            <SaveButton style={{ display: 'none' }} />
         </Toolbar>
      );
   };

   return (
      <>
         <BackButton path="/articles_setup" />
         <span className="h4">Article Setup</span>
         <TabbedForm onSubmit={onSaveToDB} toolbar={<CustomToolbar />}>
            <FormTab label="main" onClick={closeDrawer}>
               <FormControl sx={{ m: 1, minWidth: '150px', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', minHeight: '300px', justifyContent: 'space-between' }}>
                     <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
                        <div style={{ marginRight: '20px', minWidth: '170px', maxWidth: '250px', alignSelf: 'center' }}>
                           <TextField
                              label="Article Domain"
                              fullWidth={true}
                              select={!record}
                              onChange={({ target }) => {
                                 setSelectedArticleDomain(target.value);
                                 setPublisherId(articleDomains.find(({ article_domain }) => article_domain === target.value)?.publisher_id || '');
                                 setDomainToggle(true);
                              }}
                              sx={{ margin: '0px' }}
                              value={selectedArticleDomain}
                              disabled={record}
                           >
                              <MenuItem disabled={true}>Select Article domain</MenuItem>
                              <MenuItem value="create"><b>Create Article domain</b></MenuItem>
                              {articleDomains.map(({ article_domain }) => {
                                 return <MenuItem value={article_domain}>{article_domain}</MenuItem>;
                              })}
                           </TextField>
                        </div>

                        {selectedArticleDomain === 'create' &&
                           <CreateArticleDomain
                              setSelectedArticleDomain={setSelectedArticleDomain}
                              setToggle={setToggle}
                              setDomainToggle={setDomainToggle}
                           />
                        }

                        {selectedArticleDomain && selectedArticleDomain !== 'create' &&
                           <TextField
                              value={publisherId}
                              variant="filled"
                              label="Publisher ID"
                              sx={{ margin: '0 20px 0' }}
                              disabled
                              InputLabelProps={{ shrink: true }}
                           >
                           </TextField>
                        }
                     </div>

                     {selectedArticleDomain && selectedArticleDomain !== 'create' &&
                        <>
                           <TextField
                              value={articleURL}
                              onChange={({ target }) => setArticleURL(target.value)}
                              variant="filled"
                              label="Article URL"
                              sx={{ margin: margin, width: '1050px' }}
                              required={true}
                              InputProps={{
                                 startAdornment: <InputAdornment position="start">{selectedArticleDomain}/</InputAdornment>,
                              }}
                           >
                           </TextField>
                           <div style={{ display: 'flex', alignItems: 'center' }}>
                              <TextField
                                 label="Article ID"
                                 value={articleId}
                                 sx={{ margin: '0 20px 0 0' }}
                                 onChange={({ target }) => setArticleId(target.value)}
                              >
                              </TextField>
                              <TextField
                                 select={true}
                                 label="Published?"
                                 value={isPublished}
                                 sx={{ margin: '0 20px 0 0', minWidth: '120px' }}
                                 onChange={({ target }) => setIsPublished(target.value)}
                              >
                                 <MenuItem value={true}>Yes</MenuItem>
                                 <MenuItem value={false}>No</MenuItem>
                              </TextField>
                              <TextField
                                 type="date"
                                 onChange={({ target }) => setPublishDate(format(new Date(target.value), 'yyyy-MM-dd'))}
                                 value={publishDate}
                                 required={isPublished}
                                 label="Publish date"
                                 sx={{ margin: '0 20px 0 0' }}
                                 InputLabelProps={{ shrink: true }}
                              />
                           </div>
                           <TextField
                              label="Redirect Domain"
                              select={true}
                              onChange={({ target }) => setSelectedRedirectDomain(target.value)}
                              value={selectedRedirectDomain}
                              sx={{ margin: '20px 0 0', minWidth: '170px', maxWidth: '250px', }}
                              required={true}
                           >
                              <MenuItem disabled={true}>Select Redirect Domain</MenuItem>
                              {redirectDomains.map(({ id, redirect_link_domain }) => (
                                 <MenuItem value={id}>{redirect_link_domain}</MenuItem>
                              ))}
                           </TextField>
                        </>
                     }
                  </div>
                  <div className="d-flex w-100">
                     <div className="col-1 d-flex">
                        <Button
                           onClick={onSaveToDB}
                           variant="contained"
                           color="secondary"
                           sx={{ margin: '20px 0' }}
                           disabled={!selectedArticleDomain
                              || !selectedRedirectDomain
                              || !`${selectedArticleDomain}/${articleURL}`
                           }
                        >
                           Save
                        </Button>
                     </div>
                  </div>
               </FormControl>
            </FormTab>
            <FormTab label="redirect links setup">
               <>
                  <div className="d-flex justify-content-center align-items-center mb-1">
                     <span style={{ fontSize: '28px' }}>
                        {publisherId &&
                           <>
                              <b>Publisher ID:</b> {publisherId} |&nbsp;
                           </>
                        }
                        <b>Article ID:</b> {articleId || ''}
                        <span style={{ fontSize: '22px', margin: '0 0 15px 20px', wordBreak: 'break-all' }}>
                           <b>Article URL:</b> {`${selectedArticleDomain}/${articleURL}`}
                        </span>
                     </span>
                  </div>
                  <div className="col-2">
                     <FormControl sx={{ mt: 1, minWidth: 100 }}>
                        {selectedArticleDomain && selectedRedirectDomain &&
                           <Button
                              onClick={handleAddNewLinkClick}
                              variant="contained"
                              color="secondary"
                              fullWidth={true}
                              sx={{ textAlign: 'center' }}
                           >
                              + Add Redirect Link
                           </Button>
                        }
                     </FormControl>
                  </div>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '10px' }}>
                     <div style={{ width: '100%', flexDirection: 'column', position: 'relative' }}>
                        {selectedRows?.length > 0 && (
                           <MuiToolbar className="toolbar-style">
                              <Typography variant="subtitle1" component="div">
                                    {selectedRows.length} selected
                              </Typography>
                              <Tooltip title="Delete">
                                    <IconButton
                                       aria-label="delete"
                                       onClick={handleDelete}
                                       style={{ color: '#D32F2F', fontSize: '18px'}}
                                    >
                                       <DeleteIcon /> delete
                                    </IconButton>
                              </Tooltip>
                           </MuiToolbar>
                        )}
                        <DataGrid
                           disableColumnFilter
                           disableColumnMenu
                           disableRowSelectionOnClick
                           disableColumnSelector
                           rowSelection={true}
                           editMode="row"
                           columns={redirectLinksSetupColumns}
                           onRowClick={handleRowClick}
                           rows={redirectLinkRecords.map((item, index) => prepareRedirectLinksRow(item, index))}
                           style={{
                              width: '100%',
                              minHeight: redirectLinkRecords.length === 0 ? '200px' : 'auto'
                           }}
                           initialState={{
                              columns: {
                                 columnVisibilityModel: {
                                    id: false,
                                 },
                              },
                           }}
                           getRowClassName={(params) => params.id === selectedRow?.id && isOpen ? 'highlighted-row' : ''}
                           checkboxSelection={true}
                           onRowSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
                           rowSelectionModel={selectedRows}
                        />
                     </div>
                     <Drawer
                        variant="persistent"
                        anchor="right"
                        open={isOpen}
                        onClose={closeDrawer}
                     >
                        <div
                           style={{
                              width: 800,
                              padding: '25px',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'end',
                           }}
                        >
                           {isOpen &&
                              <>
                                 <Button onClick={closeDrawer} style={{ margin: '35px 0 15px' }}>X</Button>
                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <RedirectLinkRecordSetup
                                       redirectLinkRecord={selectedRow}
                                       setRedirectLinkSetup={setRedirectLinkSetup}
                                       articleId={articleId || ''}
                                       publisherId={publisherId}
                                       redirectDomainName={redirectLinkDomainName}
                                       redirectLinkRecords={redirectLinkRecords}
                                       isNewDestLink={isNewDestLink}
                                       setIsNewDestLink={setIsNewDestLink}
                                       isAutoUpdate={isAutoUpdate}
                                       setIsAutoUpdate={setIsAutoUpdate}
                                       key={selectedRow ? selectedRow.id : 'empty'}
                                    />
                                 </div>
                                 <Button
                                    onClick={onSaveToDB}
                                    variant="contained"
                                    color="secondary"
                                    sx={{ margin: '20px', width: '85px', top: '-120px' }}>
                                    Save
                                 </Button>
                              </>
                           }
                        </div>
                     </Drawer>
                  </div>
               </>
            </FormTab>
         </TabbedForm>
      </>
   );
};
