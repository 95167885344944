import React, { useEffect, useState } from 'react'
import {
   Edit,
   SelectInput,
   SimpleForm,
   TextInput,
   WithRecord,
} from 'react-admin'

import { transform } from '../../../../helpers'
import { BackButton } from '../../../helperComponents/BackButton'
import { httpClient } from '../../../../config'
import { platforms } from '../../../../helpers/constants'

export const ConversionsReportsEdit = props => {
   const [accounts, setAccounts] = useState([])
   const [platform, setPlatform] = useState(null)

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient(`/access/data`)
            const fetchedAccounts = JSON.parse(response.body).accountData
              .map(account => ({...account, name: `${account.account_name} - ${account.id}`}))

            setAccounts(fetchedAccounts)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   const onChange = setFunc => ({target}) => {
      setFunc(target.value)
   }

   return (
     <>
        <BackButton path="/conversion_reports"/>
        <span className="h4">Edit Conversions report setting</span>
        <Edit {...props} title=" " transform={transform('affiliate_ids')}>
           <SimpleForm>
              <TextInput source="id" label="ID"/>
              <TextInput source="report_name" label="Report name" required={true}/>
              <SelectInput source="platform"
                           label="Platform"
                           required={true}
                           onChange={onChange(setPlatform)}
                           choices={platforms.map(platform => {
                              if (platform.conversion) return {id: platform.id, name: platform.name}
                           })}
              />
              <TextInput source="affiliate_ids" label="Affiliate IDs"
                         fullWidth={true}
                         helperText="Please enter values separating them with coma"
              />
              <WithRecord render={record => {
                 if (platform === null) {
                    setPlatform(record.platform)
                 }

                 return (
                   <SelectInput source="internal_account_id"
                                label="Internal Account ID"
                                choices={accounts.filter(account => account.platform === platform)}
                   />
                 )
              }}/>
           </SimpleForm>
        </Edit>
     </>
   )
}
