import React, { useState, useEffect } from 'react';
import {
   List,
   Datagrid,
   TextField,
   FunctionField,
   TextInput,
   ReferenceArrayField,
   SingleFieldList,
   WithRecord,
   useTheme,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { CampaignIdTooltipContent } from '../../../helperComponents/CampaignIdTooltipContent';
import { limitedArrayField, searchInputWidth } from '../../../../helpers';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { Tooltip } from '@mui/material';
import { httpClient } from '../../../../config';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';

export const ReportSponsoredSettingsList = props => {
   const [campaignsHashMap, setCampaignsHashMap] = useState(new Map());

   const rowClassName = useRowStyle();

   useEffect(() => {
      try {
         const doRequest = async () => {
            const campaignsResponse = await httpClient('/tables/reference_fields/campaign_names');

            const fetchedCampaigns = JSON.parse(campaignsResponse.body) || [];
            const initialCampaignsMap = new Map();

            fetchedCampaigns.forEach(({id, campaign}) => initialCampaignsMap.set(id, campaign));

            setCampaignsHashMap(initialCampaignsMap);
         };

         doRequest();
      } catch(err) {
         console.log(err);
      }
   });

   return (
     <>
        <TitleAboveTable title="Report sponsored settings"/>
        <List {...props}
              title=" "
              actions={<ToolBarRowStyleToggle/>}
              pagination={<TablePagination/>}
              filters={[
                 <TextInput source="q"
                            label="ID, Name, Google Campaign ID, Offer ID, Excluded sub3 ID, Report ID"
                            alwaysOn
                            sx={{
                               width: '580px',
                            }}
                 />,
        ]}
        >
           <Datagrid rowClick="edit" bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="name"/>
              <WithRecord
                 label="Google Campaign IDs"
                 render={({google_campaign_ids}) => (
                    <Tooltip title={
                       google_campaign_ids && google_campaign_ids.map(campaignId => (
                          <div>{`${campaignId} | ${campaignsHashMap.get(campaignId) || 'Not found'}`}</div>
                       ))
                    } placement="left-start">
                       <div>
                          <FunctionField
                             render={({google_campaign_ids}) => limitedArrayField(google_campaign_ids, 4)}
                          />
                       </div>
                    </Tooltip>
                 )}
              />
              <WithRecord
                label="Meta Campaign IDs"
                render={({ meta_campaign_ids }) => (
                  <Tooltip
                     title={<CampaignIdTooltipContent campaignIds={meta_campaign_ids} campaignsHashMap={campaignsHashMap} />}
                     placement="left-start"
                  >
                     <div>
                     <FunctionField
                        render={({ meta_campaign_ids }) => limitedArrayField(meta_campaign_ids, 4)}
                     />
                     </div>
                  </Tooltip>
                 )}
              />
              <TextField source="offer_ids" label="Offer IDs"/>
              <FunctionField
                source="excluded_sub3_ids"
                label="Excluded sub3 IDs"
                render={record => (
                  <div style={{ width: '300px', wordWrap: 'break-word' }}>
                    {record.excluded_sub3_ids}
                  </div>
                )}
              />
              <TextField source="report_ids" label="Report IDs"/>
              <ReferenceArrayField
                reference="reference_fields/report_names"
                source="report_ids"
                label="Report name">
                 <SingleFieldList linkType={false}>
                    <TextField source="report_name"/>
                 </SingleFieldList>
              </ReferenceArrayField>
           </Datagrid>
        </List>
     </>
   );
};