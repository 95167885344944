import React from 'react';
import {
   Datagrid,
   List,
   TextField, TextInput,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';
import { SAMPLE_SYSTEM_LOCALES } from '../../../../helpers/constants';

export const AffiliateAccessList = props => {
   const rowClassName = useRowStyle();

   const {
      ADD_AFFILIATE,
   } = SAMPLE_SYSTEM_LOCALES;

   return (
      <>
         <TitleAboveTable title="Affiliate Access"/>
         <div className="px-2">
            <h6>
               Adding an Affiliate to this list will enable the Tune Sample System UI in the dashboard of the Affiliate.
               Removing an Affiliate from this list will undo this functionality.
            </h6>
         </div>
         <List {...props}
               title=" "
               actions={<ToolBarRowStyleToggle label={ADD_AFFILIATE} />}
               pagination={<TablePagination/>}
               perPage={50}
               filters={[
                  <TextInput source="q"
                             label="Affiliate ID, Affiliate Name"
                             alwaysOn
                             sx={{
                                width: '600px',
                             }}
                  />,

               ]}
         >
            <Datagrid className={rowClassName} rowClick="edit">
               <TextField source="id" label="ID"/>
               <TextField source="affiliate_id" label="Affiliate ID"/>
               <TextField source="affiliate" label="Affiliate"/>
            </Datagrid>
         </List>
      </>
   );
};
