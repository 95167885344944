import React, { useEffect, useState } from 'react';
import {
   Datagrid,
   List,
   TextField,
   DateField,
   SelectInput,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { Button } from '@mui/material';
import axios from 'axios';
import { ActionsWithoutCreate } from '../../settings/cron_settings/helpers/ActionsWithoutCreate';


export const AffiliateSampleRequestsList = props => {
   const [affiliates, setAffiliates] = useState([]);
   const rowClassName = useRowStyle();

   useEffect(() => {
      const doRequest = async () => {
         const fetchedAffiliates = await axios.get('/tables/sample_system/affiliate_sample_requests/related_affiliates').then(data => data.data);

         setAffiliates(fetchedAffiliates);
      };

      doRequest();
   }, []);

   const onClickSampleForm = () => {
      window.open(`${process.env.REACT_APP_TUNE_SAMPLE_FORM_URL}/sample_request_form`);
   };

   return (
      <>
         <TitleAboveTable title="All Affiliate Sample Requests"/>
         <List {...props}
               title=" "
               actions={<ActionsWithoutCreate AdditionalButton={
                  <Button
                     size="small"
                     onClick={onClickSampleForm}
                  >
                     create
                  </Button>
               }/>}
               filterDefaultValues={{status: 'open'}}
               sort={{field: 'modified_date', order: 'DESC'}}
               filters={[
                  <SelectInput
                     source="status"
                     alwaysOn={true}
                     choices={[
                        {id: 'open', name: 'Open'},
                        {id: 'closed', name: 'Closed'},
                        {id: 'open_and_closed', name: 'Open/Closed'},
                     ]}
                  />,
                  <SelectInput
                     source="affiliate_id"
                     label="Affiliate"
                     choices={affiliates.map(item => ({id: item.affiliate_id, name: item.affiliate}))}
                  />,
               ]}
               perPage={50}
               pagination={<TablePagination/>}
         >
            <Datagrid bulkActionButtons={<EmptyBulkActionButtons/>}
                      className={rowClassName}
                      rowClick="edit"
            >
               <TextField source="id" label="Affiliate ID"/>
               <TextField source="affiliate" label="Affiliate"/>
               <TextField source="number_of_open_requests" label="Open Requests"/>
               <TextField source="number_of_closed_requests" label="Closed Requests"/>
               <DateField source="modified_date" label="Modified Date"/>
               <TextField source="status" label="Status"/>
            </Datagrid>
         </List>
      </>
   );
};
