import { format, subDays } from 'date-fns'

export const darkModeFieldsColor = '#30313d'

export const dateShortcuts = [
   'Yesterday',
   'Previous 3 days',
   'Custom'
]

export const getDatesFromShortcut = shortcutName => {
   if (shortcutName === 'Yesterday') {
      return {
         startDate: format(subDays(new Date(),1), 'yyyy-MM-dd'),
         endDate: format(subDays(new Date(),1), 'yyyy-MM-dd'),
      }
   }

   if (shortcutName === 'Previous 3 days') {
      return {
         startDate: format(subDays(new Date(),3), 'yyyy-MM-dd'),
         endDate: format(subDays(new Date(),1), 'yyyy-MM-dd'),
      }
   }
}