import React, { useEffect, useState } from 'react'
import {
   SimpleForm,
   Edit,
   NumberInput,
   TextInput,
   SelectArrayInput
} from 'react-admin'

import { transform } from '../../../../helpers'
import { BackButton } from '../../../helperComponents/BackButton'
import { httpClient } from '../../../../config'

export const DefaultGoogleCampaignSettingsEdit = props => {
   const [reports, setReports] = useState([])

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient(`/access/data`)
            const fetchedReports = JSON.parse(response.body).reportData.map(({id, report_name}) => ({id, name: report_name}))
            setReports(fetchedReports)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   return (
     <>
        <BackButton path="/default_google_campaign_settings"/>
        <span className="h4">Edit Google default campaign setting</span>
        <Edit {...props} title=" " transform={transform('report_ids')}>
           <SimpleForm>
              <TextInput source="id" label="ID"/>
              <NumberInput source="affiliate_id" label="Affiliate ID"/>
              <NumberInput source="offer_id" label="Offer ID"/>
              <TextInput source="campaign_default"/>
              <SelectArrayInput
                label="Reports"
                source="report_ids"
                choices={reports}
              />
           </SimpleForm>
        </Edit>
     </>
   )
}
