import { useEffect } from 'react';
import { initializeWebSocket } from '../config';

export const useWebSocket = (onMessageHandler) => {
  useEffect(() => {
    const socket = initializeWebSocket();

    socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      onMessageHandler(data);
    });

    return () => {
      socket.close();
    };
  }, [onMessageHandler]);
};
