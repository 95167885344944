import { RedirectLinkActions } from '../components/RedirectLinkActions';

export const redirectLinksSetupColumns = [
  { field: 'id', headerName: 'ID', flex: 1 },
  { 
    field: 'offer_id', 
    headerName: 'Offer', 
    flex: 1.5,
    sortComparator: (v1, v2) => {
      if (v1 === null) return 1;
      if (v2 === null) return -1;

      const num1 = parseInt(v1.split('-')[0].trim(), 10);
      const num2 = parseInt(v2.split('-')[0].trim(), 10);
      return num1 - num2;
    }
  },
  { field: 'redirect_link', headerName: 'Redirect Link', flex: 3 },
  { field: 'destination_link', headerName: 'Destination Link', flex: 3.5 },
  { 
    field: 'position', 
    headerName: 'Position', 
    flex: 1,
    sortComparator: (v1, v2) => {
      if (v1 === null) return 1;
      if (v2 === null) return -1;

      const num1 = parseInt(v1.match(/\d+/)[0], 10);
      const num2 = parseInt(v2.match(/\d+/)[0], 10);
      return num1 - num2;
    }
  },
  { field: 'affiliate', headerName: 'Affiliate', flex: 2.5 },
  {
    field: 'button',
    headerName: '',
    flex: 2,
    sortable: false,
    filterable: false,
    disableClickEventBubbling: true,
    align: 'center',
    renderCell: (params) => <RedirectLinkActions redirectLink={params.row.redirect_link} />,
  },
];

export const prepareRedirectLinksRow = (item, index) => {
  const formattedOfferName = item.offer ? item.offer.split('-')[0].trim() : '';

  return {
    id: item.id,
    offer_id: item.offer_id && `${item.offer_id} - ${formattedOfferName}`,
    redirect_link: addHttpsIfMissing(item.redirect_link),
    destination_link: item.destination_link,
    position: item.position && `Position ${item.position}`,
    affiliate: `${item.affiliate_id} - ${item.affiliate}`,
  };
};

export const unsupportedDeepLinkOffers = [2, 4, 6, 112, 218];

export const getDestinationLink = (props) => {
  const {
     articleId = '',
     publisherId = '',
     subBrand = '',
     position = '',
     brand = '',
     offerId = '',
     affiliateId = '',
     params = '',
     deepLink = '',
  } = props;

  let queryParams = `&source=${articleId}&aff_sub2=${publisherId || ''}${subBrand ? '&aff_sub3=' + subBrand : ''}&aff_sub4=${brand}${position ? '&aff_sub5=pos' + position : ''}`;
  let idQueryParams = `offer_id=${offerId || ''}&aff_id=${affiliateId}`;
  let additionalQueryParams = params && params;
  let deepLinkParams = !unsupportedDeepLinkOffers.includes(offerId) && deepLink;

  let destinationLink = `https://clicks.trackcb.com/aff_c?${idQueryParams}${queryParams}${additionalQueryParams}${deepLinkParams || ''}`;

  return destinationLink;
};

export const addDeepLinkToDestination = (props) => {
  const {
    destinationLink = '',
    offerId = '',
    deepLink = '',
  } = props;

  const paramsTemplate = 'pid%3D{affiliate_id}%26oid%3D{offer_id}%26tid%3D{transaction_id}';
  const deepLinkTemplate = deepLink.includes('?') ? `%26${paramsTemplate}` : `%3F${paramsTemplate}`;
  const regexPattern = new RegExp("&url=.*?" + paramsTemplate, "g");
  const formattedDestinationLink = destinationLink.replace(regexPattern, '');

  let deepLinkParams = deepLink
    && !unsupportedDeepLinkOffers.includes(offerId)
    && `&url=${encodeURIComponent(deepLink)}${deepLinkTemplate}`;

  return `${formattedDestinationLink}${deepLinkParams || ''}`;
}

export const retrieveNumberFromString = (string) => {
  const pattern = /articles_setup\/(\d+)/;
  const match = string.match(pattern);
  
  if (match && match.length > 1) {
    const number = parseInt(match[1]);
    return number;
  }
  
  return null;
};

export const removeSpecialCharacters = (activeOffer) => {
  let processedString = '';

  if (activeOffer) {
      if (activeOffer.includes('-')) {
          processedString = activeOffer.split('-')[0];
      } else {
          processedString = activeOffer;
      }
  }

  return processedString.replace(/\s/g, '').replace(/[^a-zA-Z0-9]/g, '');
};

export const ensureHttpPrefix = (url) => {
  if (!url?.startsWith("http://") && !url?.startsWith("https://")) {
      return `http://${url}`;
  }
  return url;
};

export const redirectLinkPositionChoices = Array.from({ length: 15 }, (_, i) => ({
  id: i + 1,
  name: `Position ${i + 1}`
}));

const jsonToCSV = (json) => {
  const fields = Object.keys(json[0]);
  const replacer = (key, value) => value === null ? '' : value;
  const csv = json.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(fields.join(','));
  return csv.join('\r\n');
};

const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename + '.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const transformDate = (date) => {
  return new Date(date).toLocaleDateString();
}

export const redirectLinksCustomExporter = (records) => {
  const data = records.map(record => ({
    article_id: record.article_id,
    article_url: record.article_url,
    redirect_link: record.redirect_link,
    destination_link: record.destination_link,
    position: record.position,
    offer_id: record.offer_id,
    offer: record.offer,
    affiliate_id: record.affiliate_id,
    affiliate: record.affiliate,
    is_published: record.is_published,
    publish_date: record.publish_date ? transformDate(record.publish_date) : '',
  }));
  const csv = jsonToCSV(data);
  downloadCSV(csv, 'redirect_links');
};

export const objectToString = (obj) => {
  let result = [];

  for (let key in obj) {
      if (!Array.isArray(obj[key])) {
          result.push(`${key}=${obj[key]}`);
      }
  }

  return result.join('&');
};

export const transformToQueryString = (dataObj) => {
  return Object.keys(dataObj).map(key => `&${key}=${dataObj[key]}`).join('');
};

export const addHttpsIfMissing = (link) => {
  if (!link.startsWith('http://') && !link.startsWith('https://')) {
    return `https://${link}`;
  }
  return link;
};
