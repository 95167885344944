import React from 'react';
import {
   Datagrid,
   List,
   ReferenceArrayField,
   ReferenceField,
   SingleFieldList,
   TextField,
   TextInput,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { searchInputWidth } from '../../../../helpers';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';

export const TopBrandsSettingsList = props => {
   const rowClassName = useRowStyle();

   return (
     <>
        <TitleAboveTable title="Top brands settings"/>
        <List {...props}
              title=" "
              actions={<ToolBarRowStyleToggle/>}
              pagination={<TablePagination/>}
              filters={[
                 <TextInput source="q"
                            label="ID, Affiliate ID, Offer ID, Top brand, Report ID"
                            alwaysOn
                            sx={{
                               width: searchInputWidth,
                            }}
                 />,
        ]}
        >
           <Datagrid rowClick="edit" bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="affiliate_id" label="Affiliate ID"/>
              <ReferenceField
                reference="reference_fields/affiliate_names"
                source="affiliate_id"
                label="Affiliate name"
                link={false}>
                 <TextField source="affiliate"/>
              </ReferenceField>
              <TextField source="offer_id" label="Offer ID"/>
              <ReferenceField
                reference="reference_fields/offer_names"
                source="offer_id"
                label="Offer name"
                link={false}
              >
                 <TextField source="offer"/>
              </ReferenceField>
              <TextField source="top_brand"/>
              <TextField source="report_ids" label="Report IDs"/>
              <ReferenceArrayField
                reference="reference_fields/report_names"
                source="report_ids"
                label="Report name">
                 <SingleFieldList linkType={false}>
                    <TextField source="report_name"/>
                 </SingleFieldList>
              </ReferenceArrayField>
           </Datagrid>
        </List>
     </>
   );
};