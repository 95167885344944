import { TabbedForm, FormTab } from 'react-admin';
import { MainTab } from './components/MainTab';
import { TuneTab } from './components/TuneTab';
import { TrackCBTab } from './components/TrackCBTab';
import { ToolbarAlwaysOnSaveButton } from '../../../helperComponents/ToolbarAlwaysOnSaveButton';

export const MediaPlatformSettingsForm = ({ isEdit = false }) => {
  return (
    <TabbedForm toolbar={<ToolbarAlwaysOnSaveButton />}>
      <FormTab label="Main">
        <MainTab isEdit={isEdit} />
      </FormTab>
      <FormTab label="Tune">
        <TuneTab />
      </FormTab>
      <FormTab label="TrackCB">
        <TrackCBTab />
      </FormTab>
    </TabbedForm>
  );
};
