import { useState, useEffect } from 'react';
import { fetchAffiliates } from '../utils/loaders/affiliateApi';

export const useAffiliates = () => {
  const [affiliates, setAffiliates] = useState([]);

  useEffect(() => {
    const fetchAndSetAffiliates = async () => {
      try {
        const affiliatesData = await fetchAffiliates();
        setAffiliates(affiliatesData);
      } catch (err) {
        console.error('Error fetching affiliates:', err);
      }
    };

    fetchAndSetAffiliates();
  }, []);

  return affiliates;
};
