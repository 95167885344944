import {
  DatagridConfigurable,
  TextField,
  FunctionField,
  useListContext
} from 'react-admin';
import { Tooltip } from '@mui/material';
import { TotalRows } from '../../../helperComponents/TotalRows';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { DateInOneRow } from '../../../helperComponents/DateInOneRow';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { MetaApiData } from '../../../../helpers/constants';
import { findDataSourceName } from '../../../../utils/apiUtils';
import { formatJson } from '../../../../utils/jsonUtils';
import { getColumnLabel } from '../../../../utils/meta/metaColumnsUtils';
import { metaApiDataColumns } from '../../../../config/constants/meta/metaApiDataTableConstants';

export const MetaApiDataGrid = ({ isAggregated = false, rowClick }) => {
  const rowClassName = useRowStyle();
  const { total } = useListContext();

  return (
    <>
      <TotalRows total={total} />
      <DatagridConfigurable
        bulkActionButtons={<EmptyBulkActionButtons />}
        preferenceKey="meta_api_data.datagrid"
        omit={MetaApiData.defaultOffColumns}
        className={`${rowClassName} datagrid-content`}
        rowClick={rowClick}
      >
        {isAggregated ? (
          <TextField source="count" label="Count" />
        ) : (
          <TextField source={metaApiDataColumns.id.source} label={getColumnLabel(metaApiDataColumns.id)} />
        )}
        {isAggregated ? (
          <TextField source={metaApiDataColumns.data_source.source} label={getColumnLabel(metaApiDataColumns.data_source)} />
        ) : (
          <FunctionField
            label={getColumnLabel(metaApiDataColumns.data_source)}
            source={metaApiDataColumns.data_source.source}
            render={record => findDataSourceName(record[metaApiDataColumns.data_source.source])}
          />
        )}
        <TextField source={metaApiDataColumns.settings_id.source} label={getColumnLabel(metaApiDataColumns.settings_id)} />
        <TextField source={metaApiDataColumns.pixel_id.source} label={getColumnLabel(metaApiDataColumns.pixel_id)} />
        <TextField source={metaApiDataColumns.brand_id.source} label={getColumnLabel(metaApiDataColumns.brand_id)} />
        <TextField source={metaApiDataColumns.order_id.source} label={getColumnLabel(metaApiDataColumns.order_id)} />
        <TextField source={metaApiDataColumns.fb_event_id.source} label={getColumnLabel(metaApiDataColumns.fb_event_id)} />
        <TextField source={metaApiDataColumns.internal_account_id.source} label={getColumnLabel(metaApiDataColumns.internal_account_id)} />
        <TextField source={metaApiDataColumns.account_id.source} label={getColumnLabel(metaApiDataColumns.account_id)} />
        <TextField source={metaApiDataColumns.account_name.source} label={getColumnLabel(metaApiDataColumns.account_name)} />
        <TextField source={metaApiDataColumns.campaign_id.source} label={getColumnLabel(metaApiDataColumns.campaign_id)} />
        <TextField source={metaApiDataColumns.campaign_name.source} label={getColumnLabel(metaApiDataColumns.campaign_name)} />
        <TextField source={metaApiDataColumns.transaction_id.source} label={getColumnLabel(metaApiDataColumns.transaction_id)} />
        <TextField source={metaApiDataColumns.event_name.source} label={getColumnLabel(metaApiDataColumns.event_name)} />
        <TextField source={metaApiDataColumns.value.source} label={getColumnLabel(metaApiDataColumns.value)} />
        <TextField source={metaApiDataColumns.payout_percentage.source} label={getColumnLabel(metaApiDataColumns.payout_percentage)} />
        <DateInOneRow
          source={metaApiDataColumns.event_time.source}
          label={getColumnLabel(metaApiDataColumns.event_time)}
          showTime={true}
        />
        {isAggregated ? (
          <TextField source={metaApiDataColumns.api_response.source} label={getColumnLabel(metaApiDataColumns.api_response)} />
        ) : (
          <FunctionField
            label={getColumnLabel(metaApiDataColumns.api_response)}
            source={metaApiDataColumns.api_response.source}
            render={record => (
              <Tooltip
                title={
                  <pre style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    {formatJson(record.api_response)}
                  </pre>
                }
                placement='left'
                arrow
                classes={{ popper: 'custom-tooltip' }}
              >
                <div style={{
                  width: '280px',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                  {record.api_response}
                </div>
              </Tooltip>
            )}
          />
        )}
      </DatagridConfigurable>
    </>
  );
};
