import { useState, useEffect, useCallback } from 'react';
import { Button, useListContext, useNotify } from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  META_CSV_GENERATION_STATUSES,
  META_API_ENDPOINTS,
  META_API_PLATFORM
} from '../../../../../config/constants';
import { useWebSocket } from '../../../../../hooks/useWebSocket';
import {
  generateReport,
  checkDownloadStatus,
  initiateDownload,
  getMetaApiExportButtonLabel
} from '../../../../../utils/downloadUtils';
import { getSelectedColumnsFromLocalStorage } from '../../../../../utils/localStorageUtils';

export const ExportAllDataButton = ({ isAggregated }) => {
  const [status, setStatus] = useState(null);
  const { total: totalRowsDisplayed, filterValues, sort } = useListContext();
  const notify = useNotify();

  useEffect(() => {
    checkDownloadStatus({
      setStatus, 
      endpoint: META_API_ENDPOINTS.CHECK_CSV_STATUS, 
      notFoundStatus: META_CSV_GENERATION_STATUSES.NOT_FOUND
    });
  }, []);

  const handleWebSocketMessage = useCallback((data) => {
    if (data?.status !== undefined && data.platform === META_API_PLATFORM) {
      setStatus(data.status);
    }
  }, []);

  useWebSocket(handleWebSocketMessage);

  const handleExportAll = async (event) => {
    event.preventDefault();

    const columns = getSelectedColumnsFromLocalStorage('meta_api_data');

    if (status === META_CSV_GENERATION_STATUSES.COMPLETED) {
      initiateDownload({
        endpoint: META_API_ENDPOINTS.DOWNLOAD_CSV,
        params: {},
        setStatus,
        notFoundStatus: META_CSV_GENERATION_STATUSES.NOT_FOUND,
      });
    } else {
      const response = await generateReport({
        requestBody: {
          filters: filterValues,
          sort,
          columns,
          isAggregated
        },
        endpoint: META_API_ENDPOINTS.GENERATE_CSV,
        setStatus,
      });

      if (response.message) {
        notify(response.message);
      }
    }
  };

  return (
    <Button
      label={getMetaApiExportButtonLabel(status)}
      onClick={handleExportAll}
      disabled={
        status === META_CSV_GENERATION_STATUSES.IN_PROGRESS ||
        (status !== META_CSV_GENERATION_STATUSES.COMPLETED && totalRowsDisplayed === 0)
      }
    >
      <GetAppIcon />
    </Button>
  );
};
