import { httpClient } from '../../config';

export const fetchOldestRecordTime = async (endpoint) => {
  try {
    const response = await httpClient(endpoint);
    return JSON.parse(response.body).oldestRecordTime;
  } catch (error) {
    console.error('Error fetching oldest event time:', error);
    return null;
  }
};
