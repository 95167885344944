import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { limitedArrayField } from '../../helpers';
import { getAffiliateMap } from '../../utils/affiliateUtils';
import { useAffiliates } from '../../hooks/useAffiliates';

export const AffiliateNamesField = ({ affiliate_ids }) => {
  const [affiliateNames, setAffiliateNames] = useState({});
  const affiliates = useAffiliates();

  useEffect(() => {
    const mapAffiliatesToNames = () => {
      const affiliateNamesMap = getAffiliateMap({ affiliates, existingAffiliateIds: affiliate_ids });
      setAffiliateNames(affiliateNamesMap);
    };
  
    affiliate_ids?.length > 0 && mapAffiliatesToNames();
  }, [affiliate_ids, affiliates]);

  return (
    <Tooltip
      title={
        affiliate_ids?.map(affiliateId => (
          <div key={affiliateId}>{`${affiliateId} | ${affiliateNames[affiliateId] || 'not found'}`}</div>
        ))
      }
      placement="left-start"
    >
      <div>{limitedArrayField(affiliate_ids, 3)}</div>
    </Tooltip>
  );
};
