import { BackButton } from '../../../helperComponents/BackButton';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { prepareRow } from './helpers/prepareRow';
import { AFFILIATE_STATUSES } from '../../../../helpers/constants';
import { getDefaultStatusFilter } from '../../../../utils/sampleSystemUtils';
import { useRedirect } from 'react-admin';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
export const borderOptions = {border: '2px solid grey', borderRadius: '10px'};

export const AffiliateSampleRequestsEdit = props => {
   const {
      STATUS,
      STATUS_ALL,
      STATUS_PENDING,
      STATUS_APPROVED,
      STATUS_REJECTED,
   } = AFFILIATE_STATUSES;

   const [sampleRequests, setSampleRequests] = useState([]);
   const [affiliate, setAffiliate] = useState({});
   const [filter, setFilter] = useState(localStorage.getItem('filter') || getDefaultStatusFilter());
   const redirect = useRedirect();

   useEffect(() => {
      const doRequest = async () => {
         const affiliateId = window.location.href.split('/').pop();

         const fetchedSampleRequests = await axios.get(`/tables/sample_system/affiliate_sample_requests/get_all/${affiliateId}`)
            .then(response => response.data);

         setAffiliate({
            id: fetchedSampleRequests[0].affiliate_id,
            name: fetchedSampleRequests[0].affiliate
         });

         setSampleRequests(fetchedSampleRequests);
      };

      doRequest();
   }, []);

   const filteredRows = sampleRequests.filter(row => {
      if (filter === STATUS_PENDING && row.is_approved !== null) {
         return false;
      } else if (filter === STATUS_APPROVED && !row.is_approved) {
         return false;
      } else if (filter === STATUS_REJECTED && (row.is_approved || row.is_approved === null)) {
         return false;
      }
      return true;
   });

   const columns = [
      {field: 'requestNumber', headerName: 'Request Number', flex: 1, align: 'center'},
      {field: 'submitDate', headerName: 'Submit Date', flex: 1},
      {field: 'offer', headerName: 'Offer', flex: 1},
      {field: 'samplesRequested', headerName: 'Samples Requested', flex: 1, align: 'center', headerAlign: 'center'},
      {
         field: 'isApproved',
         headerName: 'Request Status',
         flex: 1,
         align: 'center',
         headerAlign: 'center',
         renderCell: (params) => {
            if (params.value === null) {
               return "Pending";
             } else if (params.value) {
               return "Sent to Brand";
             } else {
               return "Rejected";
             }
         },
      },
      {field: 'decisionDate', headerName: 'Request Status Date', flex: 1},
      {field: 'trackingLink', headerName: 'Shipment Tracking Link', flex: 2},
      {field: 'samplesReceived', headerName: 'Samples Received', flex: 1},
      {
         field: 'reviewSubmitted',
         headerName: 'Review Submitted',
         flex: 1,
         renderCell: (params) => {
            if (params.value) {
              return <CheckIcon sx={{ fontSize: 20 }} />;
            } else if (!params.value) {
              return <CloseIcon sx={{ fontSize: 20 }} />;
            }
          },
      },
   ];

   const handleFilterChange = event => {
      const newFilter = event.target.value;
      setFilter(newFilter);
      localStorage.setItem('filter', newFilter);
   };

   return (
      <>
         <BackButton path="/sample_system/affiliate_sample_requests"/>
         <span className="h4">{affiliate.id} | {affiliate.name} | Sample Request History</span>
         <div className="p-3">
            <FormControl sx={{ width: 200 }}>
               <InputLabel id="status-select-label">{STATUS}</InputLabel>
               <Select
                  labelId="status-select-label"
                  value={filter}
                  onChange={handleFilterChange}
               >
                  <MenuItem value={STATUS_ALL}>{STATUS_ALL}</MenuItem>
                  <MenuItem value={STATUS_PENDING}>{STATUS_PENDING}</MenuItem>
                  <MenuItem value={STATUS_APPROVED}>Sent to Brand</MenuItem>
                  <MenuItem value={STATUS_REJECTED}>{STATUS_REJECTED}</MenuItem>
               </Select>
            </FormControl>
            <div className="d-flex justify-content-center">
               <DataGrid
                  disableColumnFilter
                  disableColumnMenu
                  disableRowSelectionOnClick
                  disableColumnSelector
                  rowSelection={true}
                  editMode="row"
                  columns={columns}
                  onRowClick={event => redirect(`/sample_system/affiliate_sample_requests/edit/${event.row.id}?request_number=${event.row.requestNumber}`)}
                  rows={filteredRows.map((item, index) => {
                     const requestNumber = sampleRequests.length - index;
                     return prepareRow(item, requestNumber);
                  })}
               />
            </div>
         </div>
      </>
   );
};
