import { Link } from 'react-router-dom';
import { Datagrid, FunctionField, DateField, BooleanField, TextField } from 'react-admin';
import { ensureHttpPrefix } from '../articles_setup/helpers/utils';
import { ExpandedRow } from './ExpandedRow';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';

export const AggregatedRedirectLinksList = ({
  rowClick,
  setIsOpen,
  selectedRow,
  isOpen,
  data,
  toggle,
}) => {
  const handleRowClick = (id, basePath, record) => {
    if (record.count_per_article > 1) {
      setIsOpen(false);
      return 'expand';
    } else {
      return rowClick(id, basePath, record);
    }
  };

  return (
    <Datagrid
      data={data}
      expand={
        <ExpandedRow
          handleRowClick={handleRowClick}
          selectedRow={selectedRow}
          isOpen={isOpen}
          toggle={toggle}
        />
      }
      rowClick={handleRowClick}
      isRowExpandable={(record) => record.count_per_article > 1}
      bulkActionButtons={<EmptyBulkActionButtons />}
      rowStyle={(record) =>
        record.id === selectedRow?.id && isOpen && record.count_per_article < 2
          ? { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
          : {}
      }
    >
      <TextField source="id" label="ID"/>
      <FunctionField
        source="article_id"
        label="Article ID"
        render={(record) => {
          if (record.articles_setup_id) {
            return (
              <Link
                to={`/articles_setup/${record.articles_setup_id}`}
                style={{ textDecoration: 'underline' }}
                onClick={(e) => e.stopPropagation()}
              >
                {record.article_id}
              </Link>
            );
          } else {
            return record.article_id;
          }
        }}
      />
      <FunctionField
        source="article_url"
        label="Article URL"
        render={(record) => {
          const correctedURL =
            record.article_url && ensureHttpPrefix(record.article_url);
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={correctedURL}
              style={{ textDecoration: 'underline' }}
              onClick={(e) => e.stopPropagation()}
            >
              {correctedURL}
            </Link>
          );
        }}
      />
      <FunctionField
        source="redirect_link"
        label="Redirect Link"
        render={(record) => (
          <div
            style={{
              width: '270px',
              wordWrap: 'break-word',
            }}
          >
            {record.redirect_link}
          </div>
        )}
      />
      <FunctionField
        source="destination_link"
        label="Destination Link"
        render={(record) => (
          <div
            style={{
              width: '350px',
              wordWrap: 'break-word',
            }}
          >
            {record.destination_link}
          </div>
        )}
      />
      <FunctionField
        source="offer"
        label="Offer"
        render={(record, rowIndex) => (
          <>
            {record.offer_id &&
              `${record.offer_id} | ${record.offer?.split('-')[0].trim()}`}
            {record.count_per_article > 1 && (
              <a
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                <br />
                {record.count_per_article} Links
              </a>
            )}
          </>
        )}
      />
      <FunctionField
        source="affiliate"
        label="Affiliate"
        render={(record) =>
          record.affiliate_id &&
          `${record.affiliate_id} | ${record.affiliate?.split('-')[0].trim()}`
        }
      />
      <BooleanField source="is_published" label="Published?" />
      <DateField source="publish_date" label="Publish Date" />
    </Datagrid>
  );
};
