import { regenerateReport, getStatusInfo } from '../../../../utils/reportUtils';
import { darkModeStyle } from '../../../../utils/styles';

export const RegenerateReportForm = ({
  reports,
  selectRef,
  isDarkMode,
  onChange,
  onMouseOn,
  label,
  reportToRegenerateId,
  setReportToRegenerateId,
  setIsSuccess,
  status,
}) => (
  <form className="p-3" onSubmit={(e) => regenerateReport({
    event: e,
    reportToRegenerateId,
    setReportToRegenerateId,
    setIsSuccess,
    selectRef,
    status
  })}>
    <label className="form-label mb-2">{label}</label>
    <select
      name="report-to-regenerate"
      className="form-select mb-2"
      ref={selectRef}
      onChange={onChange}
      onMouseOver={onMouseOn}
      style={isDarkMode ? darkModeStyle : {}}
      required={true}
    >
      <option value="">Select report</option>
      {reports.map((report) => (
        <option
          key={report.id}
          value={report.id}
          disabled={report.status !== -1}
        >
          {report.report_name}
          {getStatusInfo(report.status).message}
        </option>
      ))}
    </select>
    <div className="d-flex">
      <button className="button btn btn-primary mt-2">Run</button>
    </div>
  </form>
);
