import { useEffect, useState } from 'react'
import { apiUrl, httpClient } from '../../../config'
import {format} from 'date-fns'

export const UpdateTunePage = () => {
   const [lastUpdateDate, setLastUpdateDate] = useState('')
   const [isLoading, setIsLoading] = useState(false)
   const [isUpdated, setIsUpdated] = useState(false)

   useEffect(() => {
      try {
         const doRequest = async () => {
            setIsLoading(true)
            const response = await httpClient(`${apiUrl}/analytics/update_tune`)
            let updateDate = JSON.parse(response.body).lastUpdateDate
            updateDate = `${format(new Date(updateDate), 'yyyy-MM-dd')} ${format(new Date(updateDate), 'HH:mm:ss')}`
            setLastUpdateDate(updateDate)
            setIsLoading(false)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [isUpdated])

   const onClick = async ({target}) => {
      try {
         setIsUpdated(false)
         const dateRange = target.dataset.range
         target.classList.add('btn-primary')
         target.classList.remove('btn-danger')
         target.classList.remove('btn-success')
         target.textContent = 'Request was sent'
         target.setAttribute('disabled', 'true')
         const response = await httpClient(`${apiUrl}/analytics/update_tune`, {
            method: 'POST',
            body: JSON.stringify({
               dateRange,
            }),
         })

         const {status, errorMessage} = JSON.parse(response.body)

         if (status === 1) {
            target.textContent = 'Done'
            target.classList.add('btn-success')
            setIsUpdated(true)
         }
         target.removeAttribute('disabled')
         target.textContent = 'Update'
      } catch (err) {
         console.log(err)
      }
   }

   return (
     <>
        <div className="container">
           <div className=" content">
              {isUpdated === true ?
                <div className="alert alert-success mt-4">Tune data for previous 3 days has been updated</div>
                :
                null
              }
              <div className="p-3 mt-5 shadow rounded border">
                 <div className="d-flex justify-content-between mb-3 row align-items-center">
                    <div className="col">
                       <p className="h5 mb-3">Update Tune data for last 3 days</p>
                       <div>
                          <button className="btn btn-primary update-tune" data-range={3} onClick={onClick}>
                             Update
                          </button>
                       </div>
                    </div>
                    <div className="col">
                       <div className="p-3 rounded shadow-sm">
                          <p className="h5 fw-normal">Data updated on:</p>
                          <div className="h6">
                             {isLoading ? 'Loading...' : lastUpdateDate || '(No data in database)'}
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </>
   )
}