import React, { useState } from 'react'
import axios from 'axios'
import { Button, TextField } from '@mui/material'
import { margin } from '../../articles_setup/helpers/constants'
import { useNotify, useRedirect } from 'react-admin'

export const CreateNewDomain = () => {
   const [domain, setDomain] = useState('')

   const redirect = useRedirect()
   const notify = useNotify()

   const onCreateNewDomain = async () => {
      const result = await axios.post('/tables/redirect_domains', {
         redirect_link_domain: domain,
      })

      if (result.status === 200) {
         notify('Domain successfully created', {type: 'success'})
      } else {
         notify('Something went wrong', {type: 'error'})
      }

      redirect(`/redirect_domains`, 'redirect_domains')
   }

   const onChangeDomain = ({target}) => {
      setDomain(target.value)
   }

   return (
      <>
         <TextField
            value={domain}
            onChange={onChangeDomain}
            variant="filled"
            label="Redirect Domain"
            sx={{margin: margin}}
         >
         </TextField>
         <Button
            onClick={onCreateNewDomain}
            variant="contained"
            color="secondary"
            sx={{margin: margin}}
         >Save Domain
         </Button>
      </>
   )
}
