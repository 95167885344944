import { httpClient } from '../../config';

export const fetchPlatformAccounts = async () => {
  try {
    const response = await httpClient('/access/data');
    return JSON.parse(response.body).accountData;
  } catch (error) {
    console.error('Error fetching platform accounts:', error);
    throw error;
  }
};
