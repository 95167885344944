export const RAW_DATA_ENDPOINTS = {
  DOWNLOAD: "/analytics/download_raw_data/download",
  GENERATE: "/analytics/download_raw_data/generate",
  CHECK_DOWNLOAD_STATUS: "/analytics/download_raw_data/check_download_status",
};

export const META_API_ENDPOINTS = {
  GENERATE_CSV: "/tables/meta_api_data/generate-meta-api-csv",
  CHECK_CSV_STATUS: "/tables/meta_api_data/check-csv-status",
  DOWNLOAD_CSV: "/tables/meta_api_data/download-meta-api-csv"
};
