import { useState, useEffect } from 'react';
import axios from 'axios';
import {
   useListContext,
   useNotify,
   useRefresh,
   useUnselectAll,
   useUpdateMany,
   Button,
} from 'react-admin';

export const BulkAccessToTune = () => {
   const {selectedIds} = useListContext();
   const [advertisersIds, setAdvertisersIds] = useState(selectedIds);
   const [isAllSelected, setIsAllSelected] = useState(false);

   const refresh = useRefresh();
   const notify = useNotify();
   const unselectAll = useUnselectAll('sample_system/advertiser_access');
   const [UpdateStatusWaiting, {isLoading}] = useUpdateMany(
      'sample_system/advertiser_access',
      {},
      {
         onSuccess: () => {
            refresh();
            notify('Access Updated', {type: 'success'});
            unselectAll();
         },
         onError: error => notify('Error: something went wrong', {type: 'warning'}),
      },
   );
   
   useEffect(() => {
      setAdvertisersIds(selectedIds);
   }, [selectedIds]);

   const onClick = data => () => {
      UpdateStatusWaiting('sample_system/advertiser_access', {ids: advertisersIds, data});
   };

   const selectAll = async () => {
      if (isAllSelected) {
         setAdvertisersIds(selectedIds);
         setIsAllSelected(false);
      } else {
         try {
            let offset = 0;
            const limit = 20;
            let areRecordsLeft = true;
            let allIds = [];

            while (areRecordsLeft) {
               const data = await axios.get(`/tables/sample_system/advertiser_access?range=[${offset},${offset+limit-1}]`)
                  .then(response => response.data);

               allIds = [...allIds, ...data.map(item => item.id)];

               if (data.length < limit) {
                  areRecordsLeft = false;
               } else {
                  offset += limit;
               }
            }

            console.log('allIds: ', allIds);
            setAdvertisersIds(allIds);
            setIsAllSelected(true);
         } catch (err) {
         console.error(err);
         }
      }
    };

   return (
      <>
         <Button
            onClick={selectAll}
            disabled={isLoading}
            style={{backgroundColor: isAllSelected ? 'darkgray' : 'initial'}} 
         >
            <span style={{margin: '0 10px', fontSize: 'inherit'}}>
               Update All
            </span>
         </Button>
         <Button
            disabled={isLoading}
            onClick={onClick({is_tune_access: true})}
         >
            tune access - yes
         </Button>
         <Button
            disabled={isLoading}
            onClick={onClick({is_tune_access: false})}
         >
            tune access - no
         </Button>
         <Button
            disabled={isLoading}
            onClick={onClick({is_show_offers: true})}
         >
            show offers - yes
         </Button>
         <Button
            disabled={isLoading}
            onClick={onClick({is_show_offers: false})}
         >
            show offers - no
         </Button>

      </>

   );
};