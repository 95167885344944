import React from 'react';
import {
   BooleanField,
   Datagrid,
   List,
   SelectArrayInput,
   TextField,
   TextInput,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { RoleNameField } from '../../../helperComponents/RoleNameField';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { roleChoices, searchInputWidth, setChoices } from '../../../../helpers';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';

export const UserSettingsList = props => {
   const rowClassName = useRowStyle();

   return (
     <>
        <TitleAboveTable title="User settings"/>
        <List {...props}
              title=" "
              actions={<ToolBarRowStyleToggle/>}
              pagination={<TablePagination/>}
              filters={[
                 <TextInput source="q"
                            label="ID, Username, DB username, Role"
                            alwaysOn
                            sx={{
                               width: searchInputWidth,
                            }}
                 />,
                 <SelectArrayInput source="user_roles_filter"
                                   label="User roles"
                                   choices={setChoices(roleChoices)}
                 />,
              ]}
        >
           <Datagrid rowClick="edit" bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="username" label="User name"/>
              <TextField source="db_username" label="DB username"/>
              <TextField source="db_password" label="DB password"/>
              <RoleNameField source="role" label="Role"/>
              <BooleanField source="has_db_access" label="DB access"/>
           </Datagrid>
        </List>
     </>
   );
};
