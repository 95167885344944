import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
} from 'react-admin';
import { Box } from '@mui/material';
import { transform } from '../../../../helpers';
import { BackButton } from '../../../helperComponents/BackButton';
import { platforms } from '../../../../helpers/constants';
import { PLATFORMS } from '../../../../config/constants/reportConstants';

export const PlatformAccountSettingsCreate = (props) => {
  return (
    <>
      <BackButton path="/platform_account_settings" />
      <span className="h4">Create platform account setting</span>
      <Create {...props} title=" " transform={transform('user_access')}>
        <SimpleForm>
        <FormDataConsumer>
            {({ formData, form, ...rest }) => (
              <Box display="flex" alignItems="center">
                <Box flex={1} mr={6}>
                  <SelectInput
                    source="platform"
                    choices={platforms.map(({ disabled, ...platform }) => platform)}
                    required={true}
                  />
                </Box>
                {formData.platform === PLATFORMS.GOOGLE && (
                  <Box flex={1}>
                    <BooleanInput 
                      source="add_to_search_term" 
                      label="Add to Google Search Terms Report" 
                      sx={{
                        whiteSpace: 'nowrap',
                        label: {
                          whiteSpace: 'nowrap',
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
          </FormDataConsumer>
          <TextInput source="account_name" label="Account Name" />
          <TextInput source="account_id" label="Account ID" />
        </SimpleForm>
      </Create>
    </>
  );
};
