import React from 'react';
import {
   BooleanField,
   Datagrid,
   List,
   TextField,
   FunctionField,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { ActionsWithoutCreate } from './helpers/ActionsWithoutCreate';
import { getTimeFromCronExp } from './helpers/getTimeFromCronExp';
import { useRowStyle } from '../../../../hooks/useRowStyle';

export const CronSettingsList = props => {
   const rowClassName = useRowStyle();

   return (
     <>
        <TitleAboveTable title="Cron settings"/>
        <List 
          {...props}
          title=" "
          actions={<ActionsWithoutCreate/>}
          pagination={<TablePagination/>}
          perPage={25}
          filters={[]}
        >
           <Datagrid rowClick="edit" bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="cron_name" label="Cron name"/>
              <FunctionField label="Cron run time" render={({expression}) => getTimeFromCronExp(expression)}/>
              <BooleanField source="is_enabled" label="is_enabled"/>
           </Datagrid>
        </List>
     </>
   );
};
