import { Button } from '@mui/material';
import { URL_TYPES } from '../../config/constants/documentationConstants';
import { fetchDocumentationUrl } from '../../utils/loaders/documentationApi';

export const DocumentationButton = ({ reference, label = 'Open' }) => {
  const handleAction = async () => {
    try {
      const { url, type } = await fetchDocumentationUrl(reference);
      if (type === URL_TYPES.LINK) {
        window.open(url, '_blank', 'noopener,noreferrer');
      } else if (type === URL_TYPES.DOWNLOAD) {
        window.location.href = url;
      }
    } catch (error) {
      console.error("Failed to perform action:", error);
    }
  };

  return (
    <Button
      style={{ minWidth: '226px', fontSize: '12px', height: 'fit-content', padding: '8px 20px' }}
      variant="contained"
      color="secondary"
      onClick={handleAction}
    >
      {label}
    </Button>
  );
};
