const textArrayNames = [
   'excluded_sub3_ids',
   'google_campaign_ids',
   'meta_campaign_ids',
   'goal_names',
]

export const transform = (...columnNames) => data => {
   const transformedObject = {...data}
   columnNames.forEach(columnName => {
      let columnValue = transformedObject[columnName]

      if (!Array.isArray(columnValue)) {
         columnValue = typeof columnValue === 'string' ?
           columnValue.split(',').map(item => {
              if (item.trim() !== '') {
                 if (textArrayNames.includes(columnName)) {
                    return item.trim()
                 } else {
                    return +item.trim()
                 }
              } else {
                 return null
              }
           })
           :
           null

         if (Array.isArray(columnValue)) columnValue = columnValue.filter(el => el !== null)
      } else {
         const filtered = transformedObject[columnName].filter(el => el !== null)
         columnValue = filtered.length ? filtered : null
      }

      transformedObject[columnName] = columnValue
   })


   return transformedObject
}