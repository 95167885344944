import React, {useEffect, useState} from 'react'
import {
   Create,
   TabbedForm,
   FormTab,
   TextInput,
   SelectInput,
   CheckboxGroupInput,
   useNotify,
   useRedirect,
} from 'react-admin'

import {BackButton} from '../../../helperComponents/BackButton'
import {setChoices, roleChoices} from '../../../../helpers'
import {httpClient} from '../../../../config'
import {Tooltip} from '@mui/material'


export const UserSettingsCreate = props => {
   const [accounts, setAccounts] = useState([])
   const [reports, setReports] = useState([])
   const [dbTables, setDbTables] = useState([])
   const [role, setRole] = useState(null)
   const notify = useNotify()
   const redirect = useRedirect()

   useEffect(() => {
      try {
         const doRequest = async () => {
            let response = await httpClient(`/access/data`)
            response = JSON.parse(response.body)

            setAccounts(response.accountData)
            setReports(response.reportData)
            setDbTables(response.dbTableData)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   const onError = () => {
      notify('User already exists', {type: 'warning'})
   }

   const onSuccess = data => {
      notify('User created successfully', {type: 'success'})
      redirect('edit', 'user_settings', data.id, data)
   }

   return (
      <>
         <BackButton path="/user_settings"/>
         <span className="h4">Create user</span>
         <Create {...props} title=" " mutationOptions={{onError, onSuccess}}>
            <TabbedForm>
               <FormTab label="main">
                  <TextInput source="username" label="User name" required={true}/>
                  <TextInput source="password" label="Password" required={true}
                             helperText="Note: This will be saved in database as Hash"
                  />
                  <TextInput source="db_password" label="DB password" defaultValue={''}/>
                  <Tooltip title={
                     <div style={{padding: '20px', fontSize: '15px'}}>
                        <div style={{paddingBottom: '10px'}}>0 | Admin - All access to dashboard</div>
                        <div style={{paddingBottom: '10px'}}>
                           1 | Media Buyer - Access to Actions & Media Settings
                        </div>
                        <div style={{paddingBottom: '10px'}}>
                           2 | Affiliate - Access to Actions page except "Update tune",<br/>
                           limited by report_settings and google_account_settings,<br/>
                           can't create new account
                        </div>
                        <div style={{paddingBottom: '10px'}}>
                           3 | Content Media Manager - Access to Bridge Link System
                        </div>
                        <div style={{paddingBottom: '10px'}}>
                          4 | Affiliate Manager - Access to limited Actions & Sample Management System
                       </div>
                       <div>
                          5 | Media Manager - Access to limited Actions, Media Platform API settings, Media Data,<br/>
                          Platform Account Settings, and Report Settings
                       </div>
                     </div>
                  } placement="right-end"
                  >
                     <SelectInput source="role" label="Role" required={true}
                                  choices={setChoices(roleChoices)}
                                  emptyText="Choose a role"
                     />
                  </Tooltip>
               </FormTab>
               <FormTab label="Account access">
                  <CheckboxGroupInput
                     row={false}
                     label="Access to Platform accounts"
                     source="access_to_accounts"
                     choices={accounts.map(account => {
                        return {id: account.id, name: account.account_name}
                     })}
                  />
               </FormTab>
               <FormTab label="Report access">
                  <CheckboxGroupInput
                     row={false}
                     label="Access to reports"
                     source="access_to_reports"
                     choices={reports.map(report => {
                        return {id: report.id, name: report.report_name}
                     })}
                  />
               </FormTab>
               <FormTab label="DB table access">
                  <CheckboxGroupInput
                     row={false}
                     label="Access to DB tables"
                     source="access_to_tables"
                     choices={dbTables.map(table => {
                        return {id: table.table_name, name: table.table_name}
                     })}
                  />
               </FormTab>
            </TabbedForm>
         </Create>
      </>
   )
}
