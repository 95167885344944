import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { darkModeFieldsColor } from '../../helperConstants';

export const UploadMetaReport = ({
  existingMetaAccounts,
  metaAccountId,
  setMetaAccountId,
  selectMetaAccountRef,
  isDarkMode,
}) => {
  return (
    <div className="row">
      <div className="col-6">
        <label htmlFor="existing-account" className="form-label">
          Choose existing account
        </label>
        <Autocomplete
          id="meta-accounts"
          options={existingMetaAccounts}
          getOptionLabel={(option) => `${option.account_name}`}
          style={{ width: 380 }}
          value={existingMetaAccounts.find(acc => acc.id === metaAccountId)}
          ref={selectMetaAccountRef}
          onChange={(event, newValue) => setMetaAccountId(newValue?.id || '')}
          renderInput={(params) => (
            <TextField
              {...params}
              name="existing-account"
              variant="outlined"
              style={{
                ...(isDarkMode
                  ? {
                      backgroundColor: darkModeFieldsColor,
                      color: 'white',
                      border: 'none',
                    }
                  : {}),
                marginTop: 0,
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
