import React from 'react'
import { Card, FormControl } from '@mui/material'

import { BackButton } from '../../../helperComponents/BackButton'
import { CreateNewDomain } from './components/CreateNewDomain'


export const RedirectDomainsCreate = props => {
   return (
      <>
         <BackButton path="/redirect_domains"/>
         <span className="h4">Create Redirect Domain</span>
         <Card sx={{padding: '10px'}}>
            <div className="row">
               <div className="col-2">
                  <FormControl sx={{m: 1, minWidth: 200}}>
                     <CreateNewDomain/>
                  </FormControl>
               </div>
            </div>
         </Card>
      </>
   )
}
