import { useState, useEffect } from 'react';
import axios from 'axios';
import { useRecordContext, DateField } from 'react-admin';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { transformToQueryString } from '../articles_setup/helpers/utils';

export const ExpandedRow = ({
  handleRowClick,
  selectedRow,
  isOpen,
  toggle,
}) => {
  const record = useRecordContext();
  const [redirectLinkRecords, setRedirectLinkRecords] = useState([]);

  useEffect(() => {
    const fetchRedirectLinkRecords = async () => {
      if (record.article_setup_id) {
        const listParams = localStorage.getItem('RaStore.redirect_links.listParams');
        const parsedListParams = JSON.parse(listParams);

        const preparedParams = transformToQueryString(parsedListParams.filter);
  
        const fetchedRedirectLinkRecords = await axios
          .get(
            `/tables/redirect_links/records?article_setup_id=${record.article_setup_id}&${preparedParams}`
          )
          .then((data) => data.data);

        setRedirectLinkRecords(fetchedRedirectLinkRecords);
      }
    };

    fetchRedirectLinkRecords();
  }, [toggle]);

  return (
    <Card
      style={{
        margin: '20px 150px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <CardContent>
        <Table style={{ margin: '20px 0', width: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Redirect Link</TableCell>
              <TableCell>Destination Link</TableCell>
              <TableCell>Offer</TableCell>
              <TableCell>Affiliate</TableCell>
              <TableCell>Published?</TableCell>
              <TableCell>Publish Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {redirectLinkRecords.map((record) => (
              <TableRow
                key={record.id}
                onClick={() => handleRowClick(record.id, null, record)}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    record.id === selectedRow?.id && isOpen
                      ? 'rgba(0, 0, 0, 0.04)'
                      : '',
                }}
              >
                <TableCell style={{ width: '270px', wordWrap: 'break-word' }}>
                  {record.redirect_link}
                </TableCell>
                <TableCell style={{ maxWidth: '540px', width: 'auto', wordWrap: 'break-word' }}>
                  {record.destination_link}
                </TableCell>
                <TableCell>
                  {record.offer_id &&
                    `${record.offer_id} | ${record.offer
                      ?.split('-')[0]
                      .trim()}`}
                </TableCell>
                <TableCell>
                  {record.affiliate_id &&
                    `${record.affiliate_id} | ${record.affiliate
                      ?.split('-')[0]
                      .trim()}`}
                </TableCell>
                <TableCell>{record.is_published ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <DateField source="publish_date" record={record} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
