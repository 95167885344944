import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

export const BackButton = ({path}) => {
   return (
     <Link to={path} style={{textDecoration: 'none', width: '70px'}}>
        <Button style={{width: '70px', margin: '10px 0 20px 0'}} variant="contained" color="secondary">
           Back
        </Button>
     </Link>
   )
}

