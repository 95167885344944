import { format, parseISO, addMinutes } from 'date-fns';
import { fetchOldestRecordTime } from './loaders/oldestRecordTimeApi';
import { getFilterParamsFromLocalStorage } from './localStorageUtils';

export const formatToUtc = (isoDateString) => {
  const date = parseISO(isoDateString);
  const utcDate = addMinutes(date, date.getTimezoneOffset());

  return format(utcDate, "dd/MM/yyyy, HH:mm:ss");
};

export const applyDateRange = ({ selectedRange, filterValues, setFilters, setShowCalendar }) => {
  const selection = selectedRange[0];

  setFilters({
    ...filterValues,
    start_date: selection.startDate ? format(selection.startDate, 'yyyy-MM-dd') : '',
    end_date: selection.endDate ? format(selection.endDate, 'yyyy-MM-dd') : new Date(),
  });

  setShowCalendar(false);
};

export const formatDateRange = (date) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const createCustomStaticRanges = (firstRecordTime) => [
  {
    label: 'All Time',
    range: () => ({
      startDate: firstRecordTime ? new Date(firstRecordTime) : '',
      endDate: new Date(),
    }),
  },
];

export const loadInitialDates = async ({ localStorageKey, endpoint }) => {
  const { startDate, endDate } = getFilterParamsFromLocalStorage(localStorageKey);
  const oldestRecordTime = await fetchOldestRecordTime(endpoint);

  const dateRange = {
    startDate: startDate || oldestRecordTime,
    endDate: endDate || new Date(),
  };

  return {
    dateRange,
    firstRecordTime: oldestRecordTime,
  };
};
