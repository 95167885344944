import React from 'react';
import {
   Datagrid,
   List,
   TextField,
   ReferenceField,
   TextInput,
} from 'react-admin';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';


export const CampaignSettingsList = props => {
   const rowClassName = useRowStyle();

   return (
     <>
        <TitleAboveTable title="Campaign Settings"/>
        <List {...props}
              title=" "
              actions={<ToolBarRowStyleToggle/>}
              pagination={<TablePagination/>}
              filters={[
           <TextInput source="q"
                      label="ID, Internal Account ID, Campaign ID, Campaign Name"
                      alwaysOn
                      sx={{
                         width: '545px',
                      }}
           />,
        ]}>
           <Datagrid rowClick="edit" bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="internal_account_id" label="Internal Account ID"/>
              <ReferenceField source="internal_account_id"
                              reference="reference_fields/platform_account_info"
                              label="Account ID"
              >
                 <TextField source="account_id"/>
              </ReferenceField>
              <ReferenceField source="internal_account_id"
                              reference="reference_fields/platform_account_info"
                              label="Account Name"
              >
                 <TextField source="account_name"/>
              </ReferenceField>
              <ReferenceField source="internal_account_id"
                              reference="reference_fields/platform_account_info"
                              label="Platform"
              >
                 <TextField source="platform"/>
              </ReferenceField>
              <TextField source="campaign_id" label="Campaign ID"/>
              <TextField source="campaign_name" label="Campaign Name"/>
           </Datagrid>
        </List>
     </>
   );
};
