import React, { useState } from 'react'
import {
   SimpleForm,
   Edit,
   TextInput,
   SelectInput,
   BooleanInput,
   useNotify,
   WithRecord,
} from 'react-admin'

import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'

import { BackButton } from '../../../helperComponents/BackButton'
import { cronNames } from '../../../../helpers/constants'
import { setChoices } from '../../../../helpers'
import { AlwaysOnSaveButton } from './helpers/AlwaysOnSaveButton'
import { validateJsonField } from '../../../../utils/validationUtils'

export const CronSettingsEdit = props => {
   const [expression, setExpression] = useState('')
   const [toggle, setToggle] = useState(false)
   const notify = useNotify()

   const onError = () => {
      notify('Setting for this cron already exists', {type: 'error'})
   }

   return (
      <>
         <BackButton path="/cron_settings"/>
         <span className="h4">Edit Cron setting</span>
         <Edit {...props} title=" " mutationOptions={{onError, }} mutationMode="pessimistic">
            <SimpleForm toolbar={<AlwaysOnSaveButton/>}>
               <TextInput source="id" label="ID" disabled={true}/>
               <SelectInput source="cron_name"
                            label="Cron Name"
                            choices={setChoices(cronNames)}
                            disabled={true}
                            required={true}/>
               <WithRecord label="Cron run time" render={record => {
                  !expression && setExpression(record.expression)

                  return (
                     <div className="w-100">
                        <Cron value={expression} setValue={value => {
                           setExpression(value)
                           record.expression = value || record.expression
                           setToggle(prev => !prev)
                        }
                        }/>
                     </div>)
               }}/>
               <TextInput source="expression"
                          label="Expression"
                          required={true}
                          value={expression}
                          onChange={({target}) => setExpression(target.value)}
               />
               <BooleanInput source="is_enabled" label="Is enabled"/>
               <TextInput
                  source="config"
                  label="Config"
                  helperText='Please enter values in JSON format, for example: {"limit":100}'
                  multiline
                  fullWidth
                  validate={validateJsonField}
               />
            </SimpleForm>
         </Edit>
      </>
   )
}
