import React, { useState, useEffect } from 'react'
import { platforms, downloadButtonLabel } from '../../../helpers/constants'
import { darkModeFieldsColor } from '../helperConstants'
import { useTheme } from 'react-admin'
import { initializeWebSocket } from '../../../config'
import { makePostRequest } from '../../../utils/loaders'
import { RAW_DATA_ENDPOINTS, RAW_DATA_STATUSES } from '../../../config/constants';
import { initiateDownload, generateReport } from '../../../utils/downloadUtils';
import { useWebSocket } from '../../../hooks/useWebSocket';

export const DownloadRawDataPage = () => {
   const [platform, setPlatform] = useState('');
   const [type, setType] = useState('');
   const [status, setStatus] = useState(null);
   const [theme] = useTheme()

   const isDarkMode = theme?.palette.mode === 'dark'

   useEffect(() => {
      const checkDownloadStatus = async () => {
         if (platform) {
            if ((platform === 'Outbrain' || platform === 'Taboola') && !type) {
               return;
            }

            const requestBody = { platform };

            if (type) {
               requestBody.type = type;
            }

            try {
               const response = await makePostRequest(RAW_DATA_ENDPOINTS.CHECK_DOWNLOAD_STATUS, requestBody);
               const { status } = JSON.parse(response.body);

               setStatus(status);
            } catch (error) {
               setStatus(null);
            }
         }
      };

      checkDownloadStatus();
   }, [platform, type]);

   const handleWebSocketMessage = (data) => {
      if (data.status !== undefined && data.platform === platform && data.type === type) {
        setStatus(data.status);
      }
   };
  
   useWebSocket(handleWebSocketMessage);

   const handleSubmit = async (event) => {
      event.preventDefault();
   
      const isReportGeneration = status !== RAW_DATA_STATUSES.COMPLETED;
   
      if (isReportGeneration) {
         generateReport({
            requestBody: { platform, ...(type && { type }) },
            endpoint: RAW_DATA_ENDPOINTS.GENERATE,
            setStatus
         });
      } else {
         initiateDownload({
            endpoint: RAW_DATA_ENDPOINTS.DOWNLOAD,
            params: { platform, type },
            setStatus,
            notFoundStatus: RAW_DATA_STATUSES.NOT_FOUND,
         });
      }
   };

   return (
      <>
         <div className="container content">
            <form
               className="p-3 mt-5 shadow rounded border"
               onSubmit={handleSubmit}
            >
               <p className="h5">
                  Download raw data
               </p>
               <div className="row">
                  <div className="col-4">
                     <label className="form-label mb-2">Select Platform</label>
                     <select name="platform"
                             className="form-select mb-2"
                             onChange={(e) => {
                                setPlatform(e.target.value);
                                setType('');
                                setStatus(null);
                             }}
                             required={true}
                             style={isDarkMode ? { backgroundColor: darkModeFieldsColor, color: 'white', border: 'none' } : {}}
                     >
                        <option value="">Select platform</option>
                        {platforms.map(({ id, name }) => (
                           <option value={id} key={id}>{name}</option>
                        ))}
                     </select>
                  </div>
                  {(platform === 'Outbrain' || platform === 'Taboola') && (
                     <div className="col-4">
                        <label className="form-label mb-2">Select Type</label>
                        <select name="type"
                                className="form-select mb-2"
                                onChange={(e) => {
                                    setType(e.target.value)
                                    setStatus(null);
                                }}
                                required={true}
                                style={isDarkMode ? { backgroundColor: darkModeFieldsColor, color: 'white', border: 'none' } : {}}
                        >
                           <option value="">Select Type</option>
                           <option value="ad-data">Ad Data</option>
                           {platform === 'Outbrain' ?
                              <option value="section-data">Section Data</option>
                              :
                              <option value="sites-data">Sites Data</option>
                           }
                        </select>
                     </div>
                  )}
               </div>
               <div className="row">
                  <div className="cold d-flex justify-content-end">
                     <button
                        className={`btn ${status === RAW_DATA_STATUSES.COMPLETED ? 'btn-success' : 'btn-primary'}`}
                        type="submit"
                        disabled={status === null || status === RAW_DATA_STATUSES.IN_PROGRESS}
                     >
                        {downloadButtonLabel[status] || downloadButtonLabel[RAW_DATA_STATUSES.NOT_FOUND]}
                     </button>
                  </div>
               </div>
            </form>
         </div>
      </>
   );
};
