import {
  useDataProvider,
  useNotify,
  useRedirect,
  useStore,
  BulkDeleteButton,
} from 'react-admin';
import { Button } from '@mui/material';

export const BulkAction = ({
  showCopyButton = true,
  showDeleteButton = true,
  ...props
}) => {
  const { resource, selectedIds } = props;
  const recordId = selectedIds[0] || 0;

  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const [copiedRecord, setCopiedRecord] = useStore(
    `${resource}.record_copy`,
    null
  );

  const onClickCopy = async () => {
    if (selectedIds.length > 1) return notify('Please choose only one record to copy', {type: 'warning'})
    const {data} = await dataProvider.getOne(resource, {id: recordId})
    delete data.id

    setCopiedRecord(data)
    redirect(`/${resource}/create`)
  }

  return (
    <>
      {showCopyButton && (
        <Button size="small" onClick={onClickCopy}>
          Copy
        </Button>
      )}
      {showDeleteButton && <BulkDeleteButton />}
    </>
  );
};
