import { useState, useEffect } from 'react'
import { Card, Button } from '@mui/material'
import { httpClient } from '../../config'
import { BackButton } from './BackButton'
import { TitleAboveTable } from './TitleAboveTable'

export const UserIDsBulkUpdatePage = props => {
   const [users, setUsers] = useState([])
   const [accounts, setAccounts] = useState([])
   const [selectedUser, setSelectedUser] = useState('')
   const [checkedIds, setCheckedIds] = useState([])
   const [checkedNames, setCheckedNames] = useState([])
   const [isChecked, setIsChecked] = useState(false)
   const [status, setStatus] = useState(0)

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient(`/access/data`)
            const users = JSON.parse(response.body).userData
            const accountData = JSON.parse(response.body).accountData
            setUsers(users)
            setAccounts(accountData)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   const onSelectAll = () => {
      setIsChecked(!isChecked)
      setCheckedIds(accounts.map(acc => acc.id))
      setCheckedNames(accounts.map(acc => acc.account_name))

      if (isChecked) {
         setCheckedIds([])
         setCheckedNames([])
      }
   }

   const onSave = async event => {
      event.preventDefault()
      setStatus(0)
      const {status} = await httpClient(`/access/access_to_google_accounts`, {
         method: 'POST',
         body: JSON.stringify({
            user_id: selectedUser,
            accounts_ids: checkedIds,
         }),
      })
      setStatus(status)
   }

   const onCheck = ({target}) => {
      if (target.checked) {
         setCheckedIds(prevState => [...prevState, +target.id])
         setCheckedNames(prevState => [...prevState, target.title])
      } else {
         setCheckedIds(prevState => [...prevState.filter(el => +el !== +target.id)])
         setCheckedNames(prevState => [...prevState.filter(el => el !== target.title)])
      }
   }

   const onSelectUser = ({target}) => {
      setStatus(0)
      setSelectedUser(+target.value)
      const filteredAccounts = accounts.filter(account => account.user_access.includes(+target.value))
      setCheckedIds(filteredAccounts.map(account => account.id))
      setCheckedNames(filteredAccounts.map(account => account.account_name))
   }

   return (
     <>
        <BackButton path="/google_accounts_settings"/>
        <TitleAboveTable title="Google Account Settings, User IDs Bulk Update:" h="h5"/>
        {status === 200 ?
          <div className="alert alert-success mt-1">
             Access changed for user: {users.find(user => user.id === selectedUser).username}
          </div>
          :
          null
        }
        {status === 500 ?
          <div className="alert alert-danger mt-1">Something went wrong</div>
          :
          null
        }
        <Card className="py-4 px-5">
           <form onSubmit={onSave}>
              <label htmlFor="user_id" className="form-label h6">Select user</label>
              <select
                className="form-select w-50 mb-3"
                name="user_id"
                required={true}
                onChange={onSelectUser}
              >
                 <option value="">Open users list</option>
                 {users.map(user => <option key={user.id} value={user.id}>{user.id} {user.username}</option>)}
              </select>
              <>
                 <label className="form-label h6">Select needed accounts</label>
                 {accounts.map(account => {
                    return (
                      <div>
                         <input
                           type="checkbox"
                           value={account.id}
                           id={account.id}
                           title={account.account_name}
                           onChange={onCheck}
                           className="form-check-input"
                           checked={checkedIds.includes(account.id)}
                         />
                         <label
                           htmlFor={account.id}
                           className="form-check-label mx-2"
                         >
                            {account.account_name}
                         </label>
                      </div>
                    )
                 })}
                 <div className="my-2">
                    <Button onClick={onSelectAll} type="button" variant="text">
                       {isChecked ? 'Deselect' : 'Select all'}
                    </Button>
                 </div>
                 <div className="mt-3">
                    <h6>You selected:</h6>
                    <div className="list-container">
                       {checkedNames.length ?
                         checkedNames.map(accName => (
                           <span className="chip">{accName}</span>
                         ))
                         :
                         <span>No selected accounts</span>
                       }
                    </div>
                    <h6>For user:</h6>
                    {selectedUser ?
                      <span className="chip">{users.find(user => user.id === selectedUser).username}</span>
                      :
                      <span>User are not selected</span>
                    }
                 </div>
                 <Button type="submit" variant="contained" className="mt-4" disabled={!selectedUser}>Save</Button>
              </>
           </form>
        </Card>
     </>
   )
}