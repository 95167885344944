import React, { useState } from 'react'
import { BackButton } from '../../../helperComponents/BackButton'
import {
   BooleanInput,
   Edit,
   SimpleForm,
   WithRecord
} from 'react-admin'
import { ToolBarWithoutDelete } from './components/ToolBarWithoutDelete'

export const AdvertiserAccessEdit = props => {
   const [advertiser, setAdvertiser] = useState({})

   return (
      <>
         <BackButton path="/sample_system/advertiser_access"/>
         <span className="h4">Edit access for {advertiser.advertiser} {advertiser.advertiser_id}</span>
         <Edit {...props}
               title=" "
         >
            <SimpleForm
               toolbar={<ToolBarWithoutDelete/>}
            >
               <WithRecord render={record => {
                  setAdvertiser(record)
               }
               }/>
               <BooleanInput source="is_tune_access"
                             label="Tune Access"
               />
               <BooleanInput source="is_show_offers"
                             label="Show Offers"
               />
            </SimpleForm>
         </Edit>
      </>
   )
}