import TextField from '@mui/material/TextField';

export const CustomDisplayField = ({ label, value, isDisabled = false }) => (
  <TextField
    label={label}
    value={value || ""}
    variant="filled"
    disabled={isDisabled}
    style={{
      marginTop: "8px",
      marginBottom: "28px",
      minWidth: "600px",
    }}
  />
);
