import { DeleteButton, SaveButton, Toolbar } from 'react-admin';

export const ToolbarAlwaysOnSaveButton = () => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton alwaysEnable />
      <DeleteButton />
    </Toolbar>
  );
};
