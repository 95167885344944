import { useState, useEffect } from 'react';
import { useTheme } from 'react-admin';
import { platforms } from '../../../helpers/constants';
import { darkModeFieldsColor, dateShortcuts, getDatesFromShortcut } from '../helperConstants';
import { generateConvReportOptions, fetchConversionReports } from '../../../utils/downloadConvUtils';
import { PLATFORMS } from '../../../config/constants/reportConstants';

export const DownloadConversionReportPage = () => {
  const { GOOGLE, META, BING } = PLATFORMS;
  const [platform, setPlatform] = useState('');
  const [dateShortcut, setDateShortcut] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reports, setReports] = useState({
    googleConvReports: [],
    bingConvReports: [],
    metaConvReports: [],
  });
  const { googleConvReports, bingConvReports, metaConvReports } = reports;

  const [theme] = useTheme();
  const isDarkMode = theme?.palette.mode === 'dark';

  const mappedPlatformReports = {
    [GOOGLE]: googleConvReports,
    [BING]: bingConvReports,
    [META]: metaConvReports,
  };

  useEffect(() => {
    fetchConversionReports().then(setReports);
  }, []);

  const onChange = setFunc => ({target}) => {
     setFunc(target.value)
  };

  return (
     <>
        <div className="container content">
           {googleConvReports ?
             <form className="p-3 mt-5 shadow rounded mt-4 border" method="post"
                   action={`/analytics/download_report`}>
                <p className="h5 mb-3">Download conversions report</p>
                <div className="mb-3">
                   <div className="row">
                      <div className="col-4 mb-2">
                         <select name="platform"
                                 className="form-select"
                                 defaultValue=""
                                 onChange={onChange(setPlatform)}
                                 required={true}
                                 style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                         >
                            <option value="">Select platform</option>
                            {platforms.map(platform => {
                               if (platform.conversion) return (
                                 <option value={platform.value}>
                                    {platform.name}
                                 </option>
                               )
                            })}
                         </select>
                      </div>
                      <div className="col-4 mb-2">
                         <select name="date-shortcut"
                                 className="form-select"
                                 defaultValue=""
                                 value={dateShortcut}
                                 onChange={({target}) => {
                                    const dates = getDatesFromShortcut(target.value)

                                    setDateShortcut(target.value)
                                    setStartDate(dates.startDate)
                                    setEndDate(dates.endDate)
                                 }}
                                 style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                         >
                            <option value="">Select Date</option>
                            {
                               dateShortcuts.map(dateShortcut => (
                                  <option value={dateShortcut}>{dateShortcut}</option>
                               ))
                            }
                         </select>
                      </div>
                   </div>
                   <div className="row">
                      <div className="col mb-2">
                         <label htmlFor="start-date">Start date</label>
                         <input type="date"
                                name="start-date"
                                className="form-control"
                                required={true}
                                onChange={({target}) => {
                                   setDateShortcut('Custom')
                                   setStartDate(target.value)
                                }}
                                value={startDate}
                                style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                         />
                      </div>
                      <div className="col">
                         <label htmlFor="end-date">End date</label>
                         <input type="date"
                                name="end-date"
                                className="form-control"
                                required={true}
                                onChange={({target}) => {
                                   setDateShortcut('Custom')
                                   setEndDate(target.value)
                                }}
                                value={endDate}
                                style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                         />
                      </div>
                   </div>
                   <div className="row">
                      <div className="col mb-2">
                         <label htmlFor="" className="form-label">Choose CSV report</label>
                         <select 
                           name="conversion-report"
                           className="form-select"
                           required={true}
                           disabled={!platform}
                           style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
                         >
                            <option value="">{!platform ? 'Need to select platform first' : 'Open reports list'}</option>
                            {platform ? generateConvReportOptions(mappedPlatformReports[platform]) : null}
                         </select>
                      </div>
                   </div>
                   <div className="row">
                      <div className="cold d-flex justify-content-end">
                         <button className="btn btn-primary download-report mt-2">Download</button>
                      </div>
                   </div>
                </div>
             </form>
             :
             <div className="p-3 border rounded border-secondary mt-4">
                <p className="h5">No report settings for google csv conversions created</p>
             </div>
           }
        </div>
     </>
   )
}