import { useState } from 'react';
import {
  Button,
  useUpdateMany,
  useNotify,
  useUnselectAll,
  useRefresh,
  useListContext,
} from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MuiButton,
} from '@mui/material';

export const UpdateVisibilityButton = () => {
  const [open, setOpen] = useState(false);
  const { selectedIds } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll('report_settings');
  const refresh = useRefresh();

  const [updateVisibility, { isLoading }] = useUpdateMany(
    'report_settings',
    { ids: selectedIds, data: { is_hidden: true } },
    {
      onSuccess: () => {
        refresh();
        notify('Report deleted', { type: 'success' });
        unselectAll();
      },
      onError: (error) =>
        notify('Error: could not update visibility', { type: 'warning' }),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    updateVisibility();
    handleClose();
  };

  return (
    <div>
      <Button label="Delete" onClick={handleClickOpen} disabled={isLoading} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete Report</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected reports?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleConfirm} color="primary">
            Yes
          </MuiButton>
          <MuiButton onClick={handleClose} color="primary">
            No
          </MuiButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
