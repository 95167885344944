import { AFFILIATE_STATUSES } from '../helpers/constants';

const {
  STATUS_ALL,
  STATUS_PENDING,
} = AFFILIATE_STATUSES;

export const getDefaultStatusFilter = () => {
  const storedParams = localStorage.getItem(
    'RaStore.sample_system/affiliate_sample_requests.listParams'
  );
  if (storedParams) {
    const parsedParams = JSON.parse(storedParams);
    return parsedParams.filter && parsedParams.filter.status === 'open'
      ? STATUS_PENDING
      : STATUS_ALL;
  }
  return STATUS_PENDING;
};
