import React, { useEffect, useState } from 'react';
import {
   Datagrid,
   FunctionField,
   List,
   ReferenceField, SelectArrayInput,
   TextField,
   TextInput,
   WithRecord,
} from 'react-admin';
import { Tooltip } from '@mui/material';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { searchInputWidth, limitedArrayField } from '../../../../helpers';
import { httpClient } from '../../../../config';
import { platforms } from '../../../../helpers/constants';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';


export const ConversionsReportsList = props => {
   const [affiliatesHashMap, setAffiliatesHashMap] = useState(new Map());
   const [accounts, setAccounts] = useState([]);

   const rowClassName = useRowStyle();

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient('/tables/reference_fields/affiliate_names');
            const dataResponse = await httpClient('/access/data');
            const fetchedAccounts = JSON.parse(dataResponse.body).accountData
               .map(({id, account_name}) => ({id, name: account_name, value: id}));

            const fetchedAffiliates = JSON.parse(response.body) || [];
            const initialAffiliatesMap = new Map();

            fetchedAffiliates.forEach(({id, affiliate}) => initialAffiliatesMap.set(id, affiliate));

            setAccounts(fetchedAccounts);
            setAffiliatesHashMap(initialAffiliatesMap);
         };

         doRequest();
      } catch (err) {
         console.log(err);
      }
   }, []);

   return (
     <>
        <TitleAboveTable title="Conversion reports"/>
        <List {...props}
              title=" "
              actions={<ToolBarRowStyleToggle/>}
              pagination={<TablePagination/>}
              filters={[
                 <TextInput source="q"
                            label="ID, Report name, Affiliate ID, Internal Account ID"
                            alwaysOn
                            sx={{
                               width: searchInputWidth,
                            }}
                 />,
                 <SelectArrayInput source="platforms_filter"
                                   label="Platform"
                                   choices={platforms}
                 />,
                 <SelectArrayInput source="accounts_filter"
                                   label="Account Names"
                                   choices={accounts}
                 />,
              ]}
        >
           <Datagrid rowClick="edit" bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="report_name"/>
              <WithRecord
                label="Affiliate IDs"
                render={({affiliate_ids}) => (
                  <Tooltip title={
                     affiliate_ids && affiliate_ids.map(affiliateId => (
                       <div>{`${affiliateId} | ${affiliatesHashMap.get(affiliateId) || 'not found'}`}</div>
                     ))
                  } placement="left-start">
                     <div>
                        <FunctionField
                          render={({affiliate_ids}) => limitedArrayField(affiliate_ids, 3)}
                        />
                     </div>
                  </Tooltip>
                )}
              />
              <TextField source="internal_account_id" label="Internal Account ID"/>
              <TextField source="platform" label="Platform"/>
              <ReferenceField
                reference="reference_fields/platform_account_info"
                source="internal_account_id"
                label="Account Name"
                link={false}>
                 <TextField source="account_name"/>
              </ReferenceField>
           </Datagrid>
        </List>
     </>
   );
};
