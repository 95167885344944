import { AppBar, ToggleThemeButton } from 'react-admin'
import { Typography, Box } from '@mui/material'
import { darkTheme, lightTheme } from './themes'
import { CustomUserMenu } from './UserMenu'
import { version } from '../helpers'
import { Link } from 'react-router-dom'
import './styles/fonts.css'


export const CustomAppBar = (props) => {
   return (
     <AppBar {...props} userMenu={<CustomUserMenu/>} elevation={1} color="inherit">
        <Typography
          variant="h6"
          color="inherit"
          sx={{
             flex: 1,
             textOverflow: 'ellipsis',
             whiteSpace: 'nowrap',
             overflow: 'hidden',
          }}
          id="react-admin-title"
        />
        <img src="static/header_icon_120x75.png" alt="AffsMedia" title="AffsMedia Analytics" className="appbar-logo"/>
        <span className="app-bar-header-title">AffsMedia</span>
        <i style={{color: '#8D1A88', fontSize: '25px', marginBottom: '5px'}}>Analytics</i>
        <Box component="span" sx={{flex: 1}}/>
        <div className="row-fluid">
           <div className="text-center"><Link to="/history">{version}</Link></div>
        </div>

        <ToggleThemeButton
          lightTheme={lightTheme}
          darkTheme={darkTheme}
        />
     </AppBar>
   )
}