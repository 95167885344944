export const millisToMinutesAndSeconds = (millis) => {
   let minutes = Math.floor(millis / 60000);
   const seconds = ((millis % 60000) / 1000).toFixed(0);

   minutes = minutes < 10 ? "0" + minutes : minutes

   return (
     seconds == 60 ?
       (minutes+1) + ":00" :
       minutes + ":" + (seconds < 10 ? "0" : "") + seconds
   );
}