import React, { useEffect, useState } from 'react'
import {
   Create,
   SelectArrayInput,
   SimpleForm,
   TextInput,
} from 'react-admin'
import { transform } from '../../../../helpers'
import { BackButton } from '../../../helperComponents/BackButton'
import { httpClient } from '../../../../config'
import { RulesForPresentAccountID, RulesForAbsentAccountID } from './helperComponents/RulesComponents';

export const ReportSponsoredSettingsCreate = props => {
   const [reports, setReports] = useState([])

   useEffect(() => {
      try {
         const doRequest = async () => {
            const response = await httpClient(`/tables/reference_fields/report_names`)
            const fetchedReports = JSON.parse(response.body) || []

            setReports(fetchedReports)
         }

         doRequest()
      } catch (err) {
         console.log(err)
      }
   }, [])

   return (
     <>
        <BackButton path="/report_sponsored_settings"/>
        <span className="h4">Create report sponsored setting</span>
        <Create {...props} title=" "
                transform={transform('google_campaign_ids', 'meta_campaign_ids', 'offer_ids', 'report_ids', 'excluded_sub3_ids')}>
           <SimpleForm>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
              <TextInput
                source="name"
                required={true}
                style={{ marginRight: '1rem', flex: 1, minWidth: '250px' }}
                helperText="Sponsored Name Value in reports"
              />
              <SelectArrayInput
                source="report_ids"
                label="Report IDs"
                choices={reports.map(({id, report_name}) => ({id, name: `${report_name} - ${id}`}))}
                style={{ flex: 8 }}
                helperText="Apply rule settings to these reports"
              />
            </div>
            <RulesForPresentAccountID />
            <TextInput
              source="google_campaign_ids"
              label="Google Campaign IDs"
              fullWidth={true}
              multiline
              helperText="Please enter values separating them with coma"
            />
            <TextInput
              source="meta_campaign_ids"
              label="Meta Campaign IDs"
              fullWidth={true}
              multiline
              helperText="Please enter values separating them with coma"
            />
            <RulesForAbsentAccountID />
            <TextInput
              source="offer_ids"
              label="Offer IDs"
              helperText="Please enter values separating them with coma"
              style={{ minWidth: '600px' }}
            />
            <TextInput
              source="excluded_sub3_ids"
              helperText="Please enter values separating them with coma"
              style={{ minWidth: '600px' }}
              multiline
            />
           </SimpleForm>
        </Create>
     </>
   )
}