import React, { useEffect, useState } from 'react';
import {
   Datagrid,
   DateField,
   List,
   TextField,
   FunctionField,
   TextInput,
   WithRecord,
   SelectArrayInput,
} from 'react-admin';
import { Tooltip } from '@mui/material';
import {
   limitedArrayField,
   setChoices,
   reportTypes
} from '../../../../helpers';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { httpClient } from '../../../../config';
import { BulkUpdateStatus } from './helpers/BulkUpdateStatus';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';
import { getStatusInfo } from '../../../../utils/reportUtils';

export const ReportSettingsList = props => {
   const [userNames, setUserNames] = useState([]);
   const [affiliatesHashMap, setAffiliatesHashMap] = useState(new Map());
   const [accountsHashMap, setAccountsHashMap] = useState(new Map());

   const rowClassName = useRowStyle();

   useEffect(() => {
      try {
         const doRequest = async () => {
            const userNamesResponse = await httpClient('/tables/reference_fields/user_names');
            const affiliatesResponse = await httpClient('/tables/reference_fields/affiliate_names');
            const accountsResponse = await httpClient('/tables/reference_fields/platform_account_info');

            const fetchedUsers = JSON.parse(userNamesResponse.body) || [];
            const fetchedAffiliates = JSON.parse(affiliatesResponse.body) || [];
            const fetchedAccounts = JSON.parse(accountsResponse.body) || [];

            const initialAffiliatesMap = new Map();
            const initialAccountsMap = new Map();

            fetchedAffiliates.forEach(({id, affiliate}) => initialAffiliatesMap.set(id, affiliate));
            fetchedAccounts.forEach(({id, account_name}) => initialAccountsMap.set(id, account_name));

            setUserNames(fetchedUsers);
            setAffiliatesHashMap(initialAffiliatesMap);
            setAccountsHashMap(initialAccountsMap);
         };

         doRequest();
      } catch (err) {
         console.log(err);
      }
   }, []);

   return (
     <>
        <TitleAboveTable title="Report settings"/>
        <List {...props}
              title=" "
              actions={<ToolBarRowStyleToggle/>}
              pagination={<TablePagination/>}
              filters={[
                 <TextInput source="q"
                            label="ID, Report name, Affiliate ID, Status, Internal Account ID, User access ID"
                            alwaysOn
                            sx={{
                               width: '600px',
                            }}
                 />,
                 <SelectArrayInput source="report_types_filter"
                                   label="Report types"
                                   choices={setChoices(reportTypes)}
                 />,
                 <SelectArrayInput source="user_names_filter"
                                   label="User names"
                                   choices={userNames.map(({id, username}) => ({id, name: username}))}
                 />,
              ]}
              perPage={25}
        >
           <Datagrid rowClick="edit" bulkActionButtons={<BulkUpdateStatus/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="report_name"/>
              <TextField source="timezone"/>
              <WithRecord
                label="Affiliate IDs"
                render={({affiliate_ids}) => (
                  <Tooltip title={
                    affiliate_ids && affiliate_ids.map(affiliateId => (
                      <div>{`${affiliateId} | ${affiliatesHashMap.get(affiliateId) || 'Not found'}`}</div>
                    ))
                  } placement="left-start">
                     <div>
                        <FunctionField
                          render={({affiliate_ids}) => limitedArrayField(affiliate_ids, 3)}
                        />
                     </div>
                  </Tooltip>
                )}
              />
              <FunctionField
                label="Status"
                sortBy="status"
                render={({ status }) => getStatusInfo(status).label}
              />
              <TextField source="report_type"/>
              <WithRecord
                label="Internal Accounts IDs"
                render={({internal_account_ids}) => (
                  <Tooltip title={
                    internal_account_ids && internal_account_ids.map(accountId => (
                      <div>{`${accountId} | ${accountsHashMap.get(accountId) || 'Not found'}`}</div>
                    ))
                  } placement="left-start">
                     <div>
                        <FunctionField
                          render={({internal_account_ids}) => limitedArrayField(internal_account_ids, 3)}
                        />
                     </div>
                  </Tooltip>
                )}
              />
              <DateField source="default_start_date"/>
              <TextField source="goal_names" label="Goal names" emptyText="All"/>
           </Datagrid>
        </List>
     </>
   );
};
