import React from 'react';
import {
   BooleanField,
   Datagrid,
   List,
   SelectInput,
   TextField,
   TextInput,
} from 'react-admin';

import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { ActionsWithoutCreate } from '../../settings/cron_settings/helpers/ActionsWithoutCreate';
import { BulkAccessToTune } from './components/BulkAccessToTune';
import { TablePagination } from '../../../helperComponents/TablePagination';

export const AdvertiserAccessList = props => {
   const rowClassName = useRowStyle();

   return (
      <>
         <TitleAboveTable title="Advertiser Access"/>
         <div className="px-2">
            <h6>
               All active Advertisers are found here. Tune access to the Sample Request system for each Advertiser can
               be controlled,
               independently all offers from an Advertiser can be hidden or shown to Affiliates who have access to the
               Sample Request Form.
            </h6>
         </div>
         <List {...props}
               title=" "
               perPage={50}
               pagination={<TablePagination />}
               actions={<ActionsWithoutCreate />}
               filters={[
                  <TextInput source="q"
                             label="Advertiser ID, Advertiser Name"
                             alwaysOn
                             sx={{
                                width: '600px',
                             }}
                  />,
                  <SelectInput source="is_tune_access"
                               label="Advertiser Access"
                               choices={[{id: true, name: 'Yes'}, {id: false, name: 'No'}]}
                  />,
                  <SelectInput source="is_show_offers"
                               label="Show Offers"
                               choices={[{id: true, name: 'Yes'}, {id: false, name: 'No'}]}
                  />
               ]}
         >
            <Datagrid className={rowClassName}
                      rowClick="edit"
                      bulkActionButtons={<BulkAccessToTune/>}
            >
               <TextField source="id" label="ID"/>
               <TextField source="advertiser_id" label="Advertiser ID"/>
               <TextField source="advertiser" label="Advertiser"/>
               <BooleanField source="is_tune_access" label="Tune Access"/>
               <BooleanField source="is_show_offers" label="Show Offers"/>
            </Datagrid>
         </List>
      </>
   );
};
