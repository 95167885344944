import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, TextField, Switch, FormControlLabel, Autocomplete  } from '@mui/material';
import {
   unsupportedDeepLinkOffers,
   removeSpecialCharacters,
   getDestinationLink,
   addDeepLinkToDestination,
   redirectLinkPositionChoices,
   addHttpsIfMissing,
} from '../helpers/utils';

export const RedirectLinkRecordSetup = (props) => {
   const {
      redirectLinkRecord = null,
      setRedirectLinkSetup,
      articleId,
      publisherId,
      redirectDomainName,
      redirectLinkRecords,
      isNewDestLink,
      setIsNewDestLink,
      isAutoUpdate,
      setIsAutoUpdate
   } = props;

   const [id, setId] = useState(0);
   const [redirectLink, setRedirectLink] = useState(redirectLinkRecord?.redirect_link || '');
   const [selectedOfferId, setSelectedOfferId] = useState(redirectLinkRecord?.offer_id || null);
   const [selectedAffiliateId, setSelectedAffiliateId] = useState('1554');
   const [subBrand, setSubBrand] = useState(redirectLinkRecord?.subBrand || '');
   const [position, setPosition] = useState(redirectLinkRecord?.position || null);
   const [params, setParams] = useState(redirectLinkRecord?.params || '');
   const [destinationLink, setDestinationLink] = useState(redirectLinkRecord?.destinationLink || getDestinationLink({}));
   const [deepLink, setDeepLink] = useState(redirectLinkRecord?.deep_link || '');
   const [isDeepLinkSupported, setIsDeepLinkSupported] = useState(!unsupportedDeepLinkOffers.includes(selectedOfferId));

   const [offers, setOffers] = useState([]);
   const [affiliates, setAffiliates] = useState([]);

   const [toggle, setToggle] = useState(false);

   useEffect(() => {
      const doRequest = async () => {
         const fetchedActive = await axios('/tables/active_entities/get_active_entities?is_internal=true').then(data => data.data);

         setOffers(fetchedActive.offers);
         setAffiliates(fetchedActive.affiliates);
      };

      doRequest();
   }, []);

   useEffect(() => {
      setIsDeepLinkSupported(!unsupportedDeepLinkOffers.includes(selectedOfferId));

      if ((toggle || redirectLink === '') && isAutoUpdate) {
         const activeOffer = offers.find(({ offer_id }) => offer_id === selectedOfferId)?.offer;
         const findSimilarOffers = redirectLinkRecords
            .filter(record => parseInt(record.offer_id) === selectedOfferId && record.id !== redirectLinkRecord?.id);
         const additionalNumber = findSimilarOffers.length > 0 ? `-${findSimilarOffers.length + 1}` : '';

         const subfolder = subBrand || removeSpecialCharacters(activeOffer);
         setRedirectLink(`${redirectDomainName}/${subfolder}${articleId}${additionalNumber}`);
      }
   }, [selectedOfferId, toggle, subBrand]);

   useEffect(() => {
      if (isNewDestLink && isAutoUpdate) {
         const deepLinkRegex = /&url=(.*?)%3Fpid%3D{affiliate_id}%26oid%3D{offer_id}%26tid%3D{transaction_id}/;
         const match = destinationLink?.match(deepLinkRegex);
         const extractedDeepLink = match ? match[0] : '';

         const parts = redirectLink.replace("https://", "").split('/');
         const brandFromRedirectLink = parts.length > 1 ? parts.slice(1).join('/') : '';

         const preparedParams = {
            offerId: selectedOfferId,
            affiliateId: selectedAffiliateId,
            subBrand: subBrand,
            position: position,
            params: params,
            deepLink: extractedDeepLink,
            articleId: articleId,
            brand: brandFromRedirectLink,
            publisherId: publisherId,
         };
         setDestinationLink(getDestinationLink(preparedParams));
      }
   }, [selectedOfferId, selectedAffiliateId, subBrand, position, params, isNewDestLink, redirectLink]);

   useEffect(() => {
      setRedirectLinkSetup({
         id,
         articleSetupId: articleId,
         redirectLink: redirectLink.replace("https://", ""),
         offerId: selectedOfferId,
         affiliateId: selectedAffiliateId,
         subBrand,
         position,
         params,
         destinationLink,
         deepLink,
      });
   }, [
      id,
      articleId,
      redirectLink,
      selectedOfferId,
      selectedAffiliateId,
      subBrand,
      position,
      params,
      destinationLink,
      deepLink,
   ]);

   useEffect(() => {
      if (redirectLinkRecord) {
         redirectLinkRecord.id && setId(redirectLinkRecord.id);
         redirectLinkRecord.redirect_link && setRedirectLink(redirectLinkRecord.redirect_link);
         redirectLinkRecord.offer_id && setSelectedOfferId(redirectLinkRecord.offer_id);
         redirectLinkRecord.affiliate_id && setSelectedAffiliateId(redirectLinkRecord.affiliate_id);
         redirectLinkRecord.sub_brand && setSubBrand(redirectLinkRecord.sub_brand);
         redirectLinkRecord.position && setPosition(redirectLinkRecord.position);
         redirectLinkRecord.params && setParams(redirectLinkRecord.params);
         redirectLinkRecord.destination_link && setDestinationLink(redirectLinkRecord.destination_link);
         redirectLinkRecord.deep_link && setDeepLink(redirectLinkRecord.deep_link);
      }
   }, [redirectLinkRecord]);

   const onChange = setFunc => ({ target }) => {
      setFunc(target.value);
      setIsNewDestLink(true);
   };

   const onAddToDestinationLink = () => {
      const preparedParams = {
         destinationLink: destinationLink,
         offerId: selectedOfferId,
         deepLink: deepLink,
      };

      setDestinationLink(addDeepLinkToDestination(preparedParams));
   };

   const onDestinationLinkChange = ({ target }) => {
      setDestinationLink(target.value);
      if (isAutoUpdate) {
         setIsNewDestLink(true);
      }
   };

   return (
      <>
         <div className="row">
            <TextField
               label="Redirect Link"
               value={addHttpsIfMissing(redirectLink)}
               onChange={onChange(setRedirectLink)}
               fullWidth={true}
            />
            <div className="hr-second" />
            {offers.length > 0 && (
               <Autocomplete
                  className="p-0"
                  value={offers.find(({ offer_id }) => offer_id === selectedOfferId)}
                  options={offers}
                  getOptionLabel={(option) => `${option.offer_id} | ${option.offer}`}
                  onChange={(event, newValue) => {
                     setSelectedOfferId(newValue ? newValue.offer_id : null);
                     setToggle(true);
                     setIsNewDestLink(true);
                  }}
                  renderInput={(params) => (
                     <TextField {...params} label="Offer" fullWidth />
                  )}
               />
            )}
            {affiliates.length > 0 && (
               <Autocomplete
                  className="p-0"
                  value={affiliates.find(({ affiliate_id }) => affiliate_id === Number(selectedAffiliateId))}
                  options={affiliates}
                  getOptionLabel={(option) => `${option.affiliate_id} | ${option.affiliate}`}
                  onChange={(event, newValue) => {
                     setSelectedAffiliateId(newValue ? newValue.affiliate_id : null);
                     setIsNewDestLink(true);
                  }}
                  renderInput={(params) => (
                     <TextField {...params} label="Affiliate" fullWidth />
                  )}
               />
            )}
            <div className="d-flex justify-content-between p-0">
               <TextField
                  label="Sub-brand"
                  value={subBrand}
                  onChange={(event) => {
                     setSubBrand(event.target.value);
                     setToggle(true);
                     setIsNewDestLink(true);
                  }}
                  style={{ width: '40%' }}
               />
               <Autocomplete
                  value={redirectLinkPositionChoices.find(choice => choice.id === position)}
                  options={redirectLinkPositionChoices}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                     setPosition(newValue ? newValue.id : null);
                     setIsNewDestLink(true);
                  }}
                  renderInput={(params) => (
                     <TextField {...params} label="Position" fullWidth />
                  )}
                  style={{ width: '40%' }}
               />
            </div>
            <TextField
               label="Params"
               value={params}
               onChange={onChange(setParams)}
               fullWidth={true}
            />
            <div className="align-items-center my-3">
               <FormControlLabel
                  control={
                     <Switch
                        checked={isAutoUpdate}
                        onChange={(event) => setIsAutoUpdate(event.target.checked)}
                        name="autoUpdate"
                        color="primary"
                     />
                  }
                  label="Enable Link Builder (This will reset Redirect Link & Destination Link)"
               />
            </div>
            <TextField
               label="Deep Link"
               value={isDeepLinkSupported ? deepLink : 'Deep links not supported for this Offer'}
               onChange={onChange(setDeepLink)}
               fullWidth={true}
            />
            <Button
               variant="contained"
               color="secondary"
               sx={{ margin: '10px 10px 13px 13px', width: '170px' }}
               onClick={onAddToDestinationLink}
               disabled={!isDeepLinkSupported}
            >
               + Add Deep Link
            </Button>
            <div className="hr-second" />
            <TextField
               label="Destination Link"
               value={destinationLink}
               onChange={onDestinationLinkChange}
               fullWidth={true}
               multiline
               rowsMax={Infinity}
            />
            <div className="d-flex align-items-center">
               <a target="_blank"
                  href={addHttpsIfMissing(redirectLink)}
                  style={{ textDecoration: 'none' }} rel="noreferrer"
               >
                  <Button
                     variant="contained"
                     color="secondary"
                     sx={{ margin: '10px 10px 5px 0', width: '85px' }}
                  >
                     Test
                  </Button>
               </a>
            </div>
            <div className="hr-second" />
         </div>
      </>
   );
};
