import { parse } from 'query-string';
import { format } from 'date-fns';
import { tableAggregationSettings } from '../helpers/constants';
import { getFilterParamsFromLocalStorage } from './localStorageUtils';

export const checkValueIsValid = (options, value) => options.some(option => option.value === value);

export const getAggregateValue = (tableName) => {
  const localStorageValue = localStorage.getItem('aggregate');
  const isValueValid = checkValueIsValid(tableAggregationSettings[tableName].options, localStorageValue);
  return isValueValid ? localStorageValue : tableAggregationSettings[tableName].defaultValue;
};

export const setLocalStorageAggregate = (selectedValue) => {
  localStorage.setItem('aggregate', selectedValue);
};

const createSearchUrl = ({ path, filters, localStorageName }) => {
  const updatedFilters = populateFiltersWithDateRange({ filters, localStorageName });
  return {
    pathname: `/${path}`,
    search: `?filter=${encodeURIComponent(JSON.stringify(updatedFilters))}`,
  };
};

export const getFiltersFromLocation = (location) => {
  const queryParams = parse(location.search);
  return queryParams.filter ? JSON.parse(queryParams.filter) : {};
};

export const navigateToAggregatePath = ({ navigate, tableName, selectedValue, localStorageName, location }) => {
  const path = tableAggregationSettings[tableName]?.options.find(option => option.value === selectedValue)?.path;
  const filters = getFiltersFromLocation(location);

  navigate(createSearchUrl({ path, filters, localStorageName }));
};

export const handleAggregateChange = ({ selectedValue, navigate, location, tableName, localStorageName }) => {
  setLocalStorageAggregate(selectedValue);
  navigateToAggregatePath({ navigate, tableName, selectedValue, localStorageName, location });
};

export const handleRedirectionBasedOnFilters = ({ location, navigate, path, aggregateField, localStorageName }) => {
  const filters = getFiltersFromLocation(location);
  const aggregateValue = localStorage.getItem('aggregate') || '';

  if (shouldRedirect({ filters, aggregateValue, aggregateField })) {
    navigate(createSearchUrl({ path, filters, localStorageName }));
  }
}

const populateFiltersWithDateRange = ({ filters, localStorageName }) => {
  const { startDate, endDate } = getFilterParamsFromLocalStorage(localStorageName);

  if (startDate && endDate) {
    filters.start_date = format(new Date(startDate), 'yyyy-MM-dd');
    filters.end_date = format(new Date(endDate), 'yyyy-MM-dd');
  }

  return filters;
};

export const shouldRedirect = ({ filters, aggregateValue, aggregateField }) => {
  return !filters[aggregateField] && aggregateValue !== 'none';
}

export const getTableAggregationPath = (tableName) => {
  const aggregateValue = getAggregateValue(tableName);
  const aggregationOption = tableAggregationSettings[tableName].options.find(option => option.value === aggregateValue);
  return aggregationOption.path;
}
