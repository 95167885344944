import { useEffect, useState } from 'react';
import {
   Datagrid,
   List,
   TextField,
   SelectInput,
   TextInput,
   FunctionField,
   SelectArrayInput,
} from 'react-admin';
import { actionNames, millisToMinutesAndSeconds, setChoices } from '../../../helpers';
import { TitleAboveTable } from '../../helperComponents/TitleAboveTable';
import { TablePagination } from '../../helperComponents/TablePagination';
import { httpClient } from '../../../config';
import { EmptyBulkActionButtons } from '../../helperComponents/EmptyBulkActionButtons';
import { Tooltip } from '@mui/material';
import { useRowStyle } from '../../../hooks/useRowStyle';
import { ActionsWithoutCreate } from '../settings/cron_settings/helpers/ActionsWithoutCreate';
import { DateRangeFilter } from '../../helperComponents/DateRangeFilter';
import { DateInOneRow } from '../../helperComponents/DateInOneRow';
import { loadInitialDates } from '../../../utils/dateUtils';
import { ActionWithTooltip } from './components/ActionWithTooltip';

export const ActionsLogsList = props => {
   const [userNames, setUserNames] = useState([]);
   const [reports, setReports] = useState([]);
   const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
   const [firstRecordTime, setFirstRecordTime] = useState(null);

   const rowClassName = useRowStyle();

   useEffect(() => {
      try {
         const doRequest = async () => {
            const responseUserNames = await httpClient('/tables/allowed/reference_fields/user_names');
            const responseReports = await httpClient('/tables/allowed/reference_fields/report_names');

            const fetchedUserNames = JSON.parse(responseUserNames.body) || [];
            const fetchedReports = JSON.parse(responseReports.body) || [];

            setUserNames(fetchedUserNames);
            setReports(fetchedReports);
         };

         doRequest();
      } catch (err) {
         console.log(err);
      }
   }, []);
   
   useEffect(() => {
      const loadDates = async () => {
        const { dateRange, firstRecordTime } = await loadInitialDates({ 
         localStorageKey: 'action_logs', 
         endpoint: '/tables/action_logs/oldest-record-time' 
        });
        setDateRange(dateRange);
        setFirstRecordTime(firstRecordTime);
      };
  
      loadDates();
   }, []);

   return (
     <>
        <TitleAboveTable title="Action logs"/>
        <List {...props}
            actions={
               <ActionsWithoutCreate
                  AdditionalButton={<DateRangeFilter dateRange={dateRange} firstRecordTime={firstRecordTime} />}
               />}
              pagination={<TablePagination/>}
              filters={[
                 <SelectArrayInput source="user_names_filter"
                                   label="User names"
                                   choices={userNames.map(({username}) => ({
                                      id: username,
                                      name: username,
                                   }))}
                 />,
                 <SelectInput source="action" label="Action" choices={setChoices(actionNames)}/>,
                 <TextInput source="report" label="Report CSV"/>,
                 <SelectArrayInput source="reports_filter"
                                   label="Reports"
                                   choices={reports.map(({id, report_name}) => ({
                                      id: report_name,
                                      name: `${report_name} - ${id}`,
                                   }))}
                 />,
                 <TextInput source="account_id_filter" label="Account ID"/>,
              ]}
              title=" "
              sort={{field: 'date', order: 'DESC'}}>
           <Datagrid bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="user_name" label="User name"/>
              <FunctionField
                label="Action"
                source="action"
                render={record => <ActionWithTooltip record={record}/>}
              />
              <TextField source="report"/>
              <FunctionField
                label="Account | ID"
                render={record => record.account ? <span>{record.account} | {record.account_id}</span> : null}
              />
              <FunctionField
                label={
                 <Tooltip placement="right-end" title="Time To Complete">
                   <div>TTC</div>
                 </Tooltip>}
                sortBy="time_to_complete"
                sortByOrder="ASC"
                render={({time_to_complete}) => time_to_complete ? millisToMinutesAndSeconds(time_to_complete) : null}/>
              <DateInOneRow
                source="date"
                label="Date"
                showTime={true}
              />
              <TextField source="report_date_range" label="Report date range"/>
           </Datagrid>
        </List>
     </>
   );
};
