import React, { useState } from 'react'
import {
   SimpleForm,
   Create,
   TextInput,
   SelectInput,
   BooleanInput,
   useNotify,
} from 'react-admin'

import { Cron } from 'react-js-cron'
import 'react-js-cron/dist/styles.css'

import { BackButton } from '../../../helperComponents/BackButton'
import { cronNames } from '../../../../helpers/constants'
import { setChoices } from '../../../../helpers'
import { AlwaysOnSaveButton } from './helpers/AlwaysOnSaveButton'
import { validateJsonField } from '../../../../utils/validationUtils'

export const CronSettingsCreate = props => {
   const [expression, setExpression] = useState('0 0 * * *')
   const [cronName, setCronName] = useState('')
   const notify = useNotify()

   const onError = () => {
      notify('Setting for this cron already exists', {type: 'error'})
   }

   const initRecord = {
      expression,
      cron_name: cronName,
   }

   return (
      <>
         <BackButton path="/cron_settings"/>
         <span className="h4">Create Cron setting</span>
         <Create {...props} title=" " mutationOptions={{onError}} record={initRecord}>
            <SimpleForm toolbar={<AlwaysOnSaveButton/>}>
               <SelectInput source="cron_name"
                            label="Cron Name"
                            choices={setChoices(cronNames)}
                            required={true}
                            onChange={({target}) => setCronName(target.value)}
               />
               <Cron value={expression} setValue={setExpression}/>
               <TextInput source="expression"
                          label="Expression"
                          required={true}
                          format={() => expression}
                          disabled={true}
               />
               <BooleanInput source="is_enabled" label="Is enabled"/>
               <TextInput
                  source="config"
                  label="Config"
                  helperText='Please enter values in JSON format, for example: {"limit":100}'
                  multiline
                  fullWidth
                  validate={validateJsonField}
               />
            </SimpleForm>
         </Create>
      </>
   )
}
