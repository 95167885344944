import { apiUrl, httpClient } from './httpClient'

export const authProvider = {
   login: ({username, password}) => {
      return httpClient(`${apiUrl}/login`, {
         method: 'POST',
         body: JSON.stringify({
            username,
            password,
         }),
      }).then(({json}) => {
         if (json?.id && json?.isLogin === true) {
            localStorage.setItem('isLogin', json.isLogin)
            localStorage.setItem('user', JSON.stringify(json))
            return Promise.resolve()
         } else {
            return Promise.reject()
         }
      })
   },
   logout: () => {
      localStorage.removeItem('user')
      localStorage.removeItem('isLogin')
      localStorage.removeItem('filter')
      httpClient(`${apiUrl}/logout`)
      return Promise.resolve()
   },

   checkError: async error => {
      const {status} = error
      if (status === 401) {
         return Promise.reject()
      }
      if (status === 403) {
         return Promise.reject({redirectTo: '/', logoutUser: false})
      }

      return Promise.resolve()
   },

   checkAuth: () => {
      return localStorage.getItem('isLogin')
        ? Promise.resolve()
        : Promise.reject()
   },

   getPermissions: () => {
      let role
      let user = localStorage.getItem('user')
      if (user) {
         role = JSON.parse(user).role
      }
      return (role !== undefined && role !== null) ? Promise.resolve({role}) : Promise.reject()
   },

   getIdentity: () => {
      try {
         const {username, roleName} = JSON.parse(localStorage.getItem('user'))
         return Promise.resolve({fullName: `${username} | ${roleName}`})
      } catch (error) {
         return Promise.reject(error)
      }
   },
}
