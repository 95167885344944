export const verifyValue = (field, defaultValue) => field !== undefined ? field : defaultValue;

export const transformMediaPlatformSettings = (data) => {
  return {
    ...data,
    settings: {
      ...data.settings,
      enable_pulling_data_trackcb: verifyValue(data.settings?.enable_pulling_data_trackcb, true),
      enable_pulling_data_tune: verifyValue(data.settings?.enable_pulling_data_tune, true),
    }
  };
};
