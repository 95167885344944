import React from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { darkModeFieldsColor } from '../helperConstants'

export const ReportTypeToggle = ({id, type, onChange, firstChoice, secondChoice, labelText = 'Select Type', isDarkMode}) => {
   const {value: firstValue, text: firstText} = firstChoice || {}
   const {value: secondValue, text: secondText} = secondChoice || {}

   return (
     <>
        <label htmlFor={id} className="form-label">{labelText}</label>
        <ToggleButtonGroup
          id={id}
          aria-label="type"
          color="primary"
          value={type}
          exclusive={true}
          onChange={onChange}
          size="small"
          fullWidth={true}
          style={isDarkMode ? {backgroundColor: darkModeFieldsColor, color: 'white', border: 'none'}: {}}
        >
           {firstChoice &&
             <ToggleButton
               value={firstValue}
               sx={{
                  borderRadius: '0.25rem',
                  '&.Mui-selected': {
                     borderColor: 'inherit',
                  },
               }}>
                {firstText}
             </ToggleButton>
           }
           {secondChoice &&
             <ToggleButton
               value={secondValue}
               sx={{
                  borderRadius: '0.25rem',
                  '&.Mui-selected': {
                     border: '1px solid blue !important',
                     marginLeft: '0px !important',
                     borderColor: 'inherit',
                  },
               }}>
                {secondText}
             </ToggleButton>
           }
        </ToggleButtonGroup>
     </>
   )
}