import { format } from 'date-fns';

export const prepareRow = (item) => ({
   id: item.id,
   requestNumber: item.rowNumber,
   submitDate: format(new Date(item.submit_date), 'yyyy-MM-dd\' \'HH:mm'),
   modifiedDate: item.modified_date ? format(new Date(item.modified_date), 'yyyy-MM-dd\' \'HH:mm:ss') : null,
   brand: item.brand,
   offer: item.offer,
   samplesRequested: item.samples_requested,
   isApproved: item.is_approved,
   decisionDate: item.decision_date ? format(new Date(item.decision_date), 'yyyy-MM-dd\' \'HH:mm') : null,
   trackingLink: item.tracking_link,
   samplesReceived: item.samples_received,
   reviewSubmitted: item.review_submitted,

   productUrl1: item.product_1_url,
   productVariant1: item.product_1_variant,
   productUrl2: item.product_2_url,
   productVariant2: item.product_2_variant,
   productUrl3: item.product_3_url,
   productVariant3: item.product_3_variant,
   productUrl4: item.product_4_url,
   productVariant4: item.product_4_variant,
   productUrl5: item.product_5_url,
   productVariant5: item.product_5_variant,

   firstName: item.first_name,
   lastName: item.last_name,
   company: item.company,
   country: item.country,
   state: item.state,
   locality: item.locality,
   address: item.address,
   postcode: item.postcode,
   email: item.email,
   phone: item.phone
});
