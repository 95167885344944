import { useNotify } from 'react-admin';
import { Button } from '@mui/material';
import { addHttpsIfMissing } from '../helpers/utils';

export const RedirectLinkActions = ({ redirectLink }) => {
  const notify = useNotify();

  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(addHttpsIfMissing(redirectLink))
      .then(() => {
        notify('Redirect Link copied to clipboard!', { type: 'success' });
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
        notify('Failed to copy the link.', { type: 'warning' });
      });
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginRight: '10px', padding: '0 15px', width: '85px' }}
        onClick={handleCopy}
      >
        Copy
      </Button>
      <a
        href={addHttpsIfMissing(redirectLink)}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
        style={{ textDecoration: 'none' }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{ padding: '0 15px', width: '85px' }}
        >
          Test
        </Button>
      </a>
    </>
  );
};
