import { httpClient } from '../../config';

export const fetchDocumentationUrl = async (reference) => {
  try {
    const response = await httpClient(`/documentation/determine-url/${reference}`);
    if (response.json && 'url' in response.json && 'type' in response.json) {
      return response.json;
    } else {
      throw new Error('No URL or type found in the response.');
    }
  } catch (error) {
    console.error("Failed to retrieve the documentation link:", error);
    throw error;
  }
};
