import { Toolbar, SaveButton } from 'react-admin'


export const AlwaysOnSaveButton = () => {
   return (
      <Toolbar style={{display: 'flex', flex: '1 1 0%', justifyContent: 'space-between'}}>
         <SaveButton alwaysEnable={true} onClick={event => {
            const message = `This setting is very sensitive,
            Are you sure you want to change it?`
            !window.confirm(message) && event.preventDefault()
         }}/>
      </Toolbar>
   )
}